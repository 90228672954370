
import { always, filter, propEq } from "ramda";
import Vue, { PropType } from "vue";
import {
  CarouselImageType,
  CarouselItem,
} from "./TopLevelDepartmentCarousel.vue";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
export default Vue.extend({
  components: { BaseCarousel },
  props: {
    carouselItems: Array as PropType<CarouselItem[]>,
  },
  data() {
    return {
      carouselSetting: {
        showDots: false,
        autoplayDelay: 10,
        slidesPerPage: 1,
        paginateBySlide: true,
        showArrows: false,
        loop: true,
      },
    };
  },
  computed: {
    filtered: always(filter(propEq("mb-img", CarouselImageType.SingleImage))),
  },
});
