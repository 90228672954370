
import Vue from "vue";
import VersionOne from "~/components/promo-and-recs/variants/VersionOne.vue";
import VersionTwo from "~/components/promo-and-recs/variants/VersionTwo.vue";
import { TrackingEvents } from "~/store/tracking";

export default Vue.extend({
  components: {
    VersionOne,
    VersionTwo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeImgOverlay: {
      type: Boolean,
      default: false,
    },
    altBackgroundColour: {
      type: Boolean,
      default: true,
    },
    pathways: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    lazyLoadImage: {
      type: Boolean,
      default: () => true,
    },
    contentColour: {
      type: String,
      default: "",
      required: true,
    },
    headingTypes: {
      type: Object,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      validator(value: string) {
        return ["version 1", "version 2"].includes(value);
      },
      required: true,
    },
  },
  computed: {
    getVariant() {
      switch (this.variant) {
        case "version 1":
          return VersionOne;
        case "version 2":
          return VersionTwo;
        default:
          return VersionOne;
      }
    },
  },
  methods: {
    trackClick(trackingData: { type: string; pid?: string }) {
      const { type, pid } = trackingData;
      const data = { type, fromPath: this.$route.fullPath, toPid: pid };
      if (!pid) delete data.toPid;

      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.PromoAndRecsClick,
        data,
      });
    },
  },
});
