<template>
  <div
    ref="accordionItem"
    class="accordion-item"
    :class="{
      'accordion-item--no-top-bottom-border': noBorderTopBottom,
      'accordion-item--no-padding': noPadding,
      'accordion-item--no-x-padding': noXPadding,
      'accordion-item--no-t-padding': noTPadding,
      'accordion-item--less-padding': lessPadding,
    }"
  >
    <h3 class="accordion-item__title" @click="toggleItem()">
      <slot name="title" />
      <div
        :style="props.iconColour"
        class="flex items-center justify-center p-1 rounded-full"
        :class="altIcon || altIconR ? 'text-blue' : 'text-white'"
      >
        <PlusIcon
          v-if="!altIcon && !altIconR"
          size="xxs"
          :classes="`shrink-0 ease-in-out w-3 h-3 md:w-5 md:h-5 duration-300 ${
            expanded ? 'rotate-45' : ''
          }`"
        />

        <!-- Alternative icon (chevron) -->
        <ChevronIcon
          v-else-if="altIcon"
          :classes="`shrink-0 ease-in-out w-5 h-5 md:w-7 md:h-7 duration-300 ${
            expanded ? 'rotate-180' : ''
          }`"
          direction="down"
        />

        <!-- Alternative icon 2 (chevron) -->
        <ChevronIcon
          v-else
          :classes="`shrink-0 ease-in-out w-5 h-5 md:w-7 md:h-7 duration-300 ${
            expanded ? 'rotate-90' : ''
          }`"
          direction="right"
        />
      </div>
    </h3>

    <h4
      v-if="$slots['title-supporting-text']"
      class="accordion-item__supporting-text"
      @click="toggleItem()"
    >
      <slot name="title-supporting-text" />
    </h4>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-show="expanded" class="accordion-item__body">
        <div class="pt-4">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { ChevronIcon, PlusIcon } from "~/components/UI/icons";

const props = defineProps({
  iconColour: {
    type: String,
    required: false,
    default: "",
  },
  startExpanded: {
    type: Boolean,
    required: false,
    default: false,
  },
  altIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  // right chevrons
  altIconR: {
    type: Boolean,
    required: false,
    default: false,
  },
  noBorderTopBottom: {
    type: Boolean,
    required: false,
    default: false,
  },
  noPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  noXPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  noTPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  lessPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
  triggerScrollAndExpand: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const expanded = ref(props.startExpanded);
const accordionItem = ref(null);

function toggleItem() {
  expanded.value = !expanded.value;
}

function expandItem() {
  expanded.value = true;
  accordionItem.value.scrollIntoView({ behavior: "smooth" });
}

watch(() => props.triggerScrollAndExpand, expandItem);

function start(el) {
  el.style.height = el.scrollHeight + "px";
}

function end(el) {
  el.style.height = "";
}
</script>

<style lang="postcss" scoped>
.accordion-item {
  @apply border-grey-3 text-blue py-3 md:py-6;

  &__title,
  &__supporting-text {
    @apply font-bold text-size-4 md:text-size-5 hover:text-blue-800 cursor-pointer px-4 justify-between items-center flex;
  }

  &__supporting-text {
    @apply font-regular text-size-2 md:text-size-4 mt-2;
  }

  &__body {
    @apply px-4 text-size-2 md:text-size-4 overflow-hidden ease-in-out duration-300;
  }

  &--less-padding {
    @apply !py-2;
  }

  &--no-padding,
  &--no-padding &__title,
  &--no-padding &__supporting-text,
  &--no-padding &__body {
    @apply pt-0 px-0;
  }

  &--no-x-padding,
  &--no-x-padding &__title,
  &--no-x-padding &__supporting-text,
  &--no-x-padding &__body {
    @apply px-0;
  }

  &--no-t-padding,
  &--no-t-padding &__title,
  &--no-t-padding &__supporting-text,
  &--no-t-padding &__body {
    @apply pt-0;
  }

  &--no-top-bottom-border:first-of-type {
    @apply border-t-0 !border-b-0;
  }
  &:not(&--no-top-bottom-border:last-of-type) {
    @apply border-b-1;
  }

  /* Useage Example '/after-table-support' ?? '/aftersalesreport' - 'Suppliers Helplines' accordion item */

  :deep(.two-table-columns) {
    @apply flex flex-col md:flex-row gap-0 md:gap-14;

    table {
      @apply w-full;

      tr {
        @apply flex gap-12 w-full border-dashed border-b-1 border-grey-4;

        td {
          @apply w-full py-4 text-black-default;

          &:last-of-type {
            @apply text-right text-blue;
          }
        }
      }
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition:
    height 0.3s ease,
    opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
