<template>
  <div
    class="h-unset flex flex-1 basis-full gap-6 md:gap-10 lg:gap-6 items-center"
    :class="flexBasisClasses"
  >
    <ResponsiveImage
      v-if="images"
      :images="images"
      :alt-tag="title"
      :classes="`lg:w-full h-full lg:h-[200px] ${
        imageFit === 'cover' ? 'object-cover w-full md:w-1/2' : 'object-contain'
      }`"
    />

    <div
      :class="[
        imagePosition.classes,
        { 'items-center md:items-start w-full': imageFit === 'contain' },
      ]"
      class="flex flex-col lg:flex-col gap-4 mt-2 h-full w-full"
    >
      <component
        :is="headingType"
        v-if="title"
        :class="titleAlignment"
        :style="titleColour"
      >
        {{ title }}
      </component>
      <div>
        <div
          v-if="body"
          :class="[bodyAlignment, lineClamp]"
          :style="bodyColour"
          v-html="body"
        />
        <span
          v-if="showReadMore"
          class="underline text-blue inline-block mt-2 cursor-pointer"
          @click="toggleBodyText"
          >{{ toggleButtonText }}</span
        >
      </div>

      <DefaultButton
        v-if="cta?.linkText"
        appearance="pill"
        size="lg"
        variant="primary"
        :path="cta.externalLink"
        class="w-full mt-auto sm:!max-w-[300px]"
        :style="buttonColour"
      >
        {{ cta.linkText }}
      </DefaultButton>
    </div>
  </div>
</template>

<script>
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

export default {
  components: { DefaultButton, ResponsiveImage },
  props: {
    images: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    body: {
      type: String,
      required: false,
      default: null,
    },
    cta: {
      type: Object,
      required: false,
      default: null,
    },
    headingType: {
      type: String,
      required: true,
    },
    titleColour: {
      type: String,
      required: true,
    },
    bodyColour: {
      type: String,
      required: true,
    },
    buttonColour: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: Object,
      required: true,
    },
    titleAlignment: {
      type: String,
      required: true,
    },
    bodyAlignment: {
      type: String,
      required: true,
    },
    showReadMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageFit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expandedBody: false,
    };
  },
  computed: {
    lineClamp() {
      return this.expandedBody || !this.showReadMore
        ? "lineClamp"
        : "lineClamp lineClamp--active";
    },
    toggleButtonText() {
      return !this.expandedBody ? "Read more" : "Read less";
    },
    flexBasisClasses() {
      return this.imageFit === "cover"
        ? "md:flex-[calc(25%-5rem)] flex-col md:flex-row lg:flex-col"
        : "sm:flex-[calc(50%-4rem)] md:flex-[calc(33%-5rem)] xl:flex-[calc(25%-5rem)] flex-col";
    },
  },
  methods: {
    toggleBodyText() {
      this.expandedBody = !this.expandedBody;
    },
  },
};
</script>
