<template>
  <div class="h-full w-full flex flex-col gap-4 flex-1 basis-full">
    <component
      :is="headingType"
      v-if="title"
      :class="titleAlignment"
      :style="titleColour"
    >
      {{ title }}
    </component>
    <div :class="imagePosition.classes" class="flex flex-col gap-8">
      <ResponsiveImage :images="images" :alt-tag="title" />
      <div>
        <div
          v-if="body"
          :class="[bodyAlignment, lineClamp]"
          :style="bodyColour"
          v-html="body"
        />
        <span
          v-if="showReadMore"
          class="underline text-blue inline-block mt-2 cursor-pointer"
          @click="toggleBodyText"
          >{{ toggleButtonText }}</span
        >
      </div>
    </div>
    <DefaultButton
      v-if="cta?.linkText"
      appearance="pill"
      size="lg"
      variant="primary"
      :path="cta.externalLink"
      class="mt-8 w-full sm_md:max-w-[300px] sm_md:mx-auto"
      :style="buttonColour"
    >
      {{ cta.linkText }}
    </DefaultButton>
  </div>
</template>

<script>
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

export default {
  components: { DefaultButton, ResponsiveImage },
  props: {
    images: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    body: {
      type: String,
      required: false,
      default: null,
    },
    cta: {
      type: Object,
      required: false,
      default: null,
    },
    headingType: {
      type: String,
      required: true,
    },
    titleColour: {
      type: String,
      required: true,
    },
    bodyColour: {
      type: String,
      required: true,
    },
    buttonColour: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: Object,
      required: true,
    },
    titleAlignment: {
      type: String,
      required: true,
    },
    bodyAlignment: {
      type: String,
      required: true,
    },
    showReadMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expandedBody: false,
    };
  },
  computed: {
    lineClamp() {
      return this.expandedBody || !this.showReadMore
        ? "lineClamp"
        : "lineClamp lineClamp--active";
    },
    toggleButtonText() {
      return !this.expandedBody ? "Read more" : "Read less";
    },
  },
  methods: {
    toggleBodyText() {
      this.expandedBody = !this.expandedBody;
    },
  },
};
</script>
