var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full flex gap-6 md:gap-12 items-center",class:_vm.imageClasses},[_c('div',{staticClass:"w-full md:w-2/4 flex flex-col gap-4"},[(_vm.title)?_c(_vm.headingType,{tag:"component",class:_vm.titleAlignment,style:(_vm.titleColour)},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-6",class:_vm.imagePosition.position === 'bottom' ? _vm.imagePosition.classes : ''},[(
          _vm.images &&
          (_vm.imagePosition.position === 'top' ||
            _vm.imagePosition.position === 'bottom')
        )?_c('ResponsiveImage',{attrs:{"images":_vm.images,"alt-tag":_vm.title,"classes":"max-h-[250px] sm_md:max-w-[500px] w-full"}}):_vm._e(),_vm._v(" "),_c('div',[(_vm.body)?_c('div',{class:_vm.lineClamp,style:(_vm.bodyColour),domProps:{"innerHTML":_vm._s(_vm.body)}}):_vm._e(),_vm._v(" "),(_vm.showReadMore)?_c('span',{staticClass:"underline text-blue inline-block mt-2 cursor-pointer",on:{"click":_vm.toggleBodyText}},[_vm._v(_vm._s(_vm.toggleButtonText))]):_vm._e()])],1),_vm._v(" "),(_vm.cta.linkText)?_c('DefaultButton',{staticClass:"mt-6 max-w-[300px] sm_md:mx-auto !ml-0",style:(_vm.buttonColour),attrs:{"appearance":"pill","size":"lg","variant":"primary","path":_vm.cta.externalLink}},[_vm._v("\n      "+_vm._s(_vm.cta.linkText)+"\n    ")]):_vm._e()],1),_vm._v(" "),(
      _vm.images &&
      (_vm.imagePosition.position === 'left' ||
        _vm.imagePosition.position === 'right')
    )?_c('ResponsiveImage',{attrs:{"images":_vm.images,"alt-tag":_vm.title,"classes":"max-h-[250px]', 'sm_md:max-w-[500px] w-full md:w-2/4"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }