interface BloomreachBackgroundImage {
  contentType: "brxsaas:backgroundImage";
  displayName: string;
  imageUrl: string;
  imageUrlMobile: string;
  imageUrlTablet: string;
  name: string;
}

export interface Images {
  desktopSrc: string;
  tabletSrc: string;
  mobileSrc: string;
}

export const getDeviceImages = (
  image: BloomreachBackgroundImage
): Images | Object => {
  if (image === null) return {};

  const { imageUrl, imageUrlTablet, imageUrlMobile } = image;
  return {
    desktopSrc: imageUrl,
    tabletSrc: imageUrlTablet,
    mobileSrc: imageUrlMobile,
  };
};
