<template>
  <a :href="url" :class="classes" :title="title" @click="clicked">
    <slot name="linkPrefix" />
    {{ linkText }}
    <slot name="linkContent" />
  </a>
</template>

<script>
export default {
  name: "ExternalLink",
  props: {
    url: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    clickAction: {
      type: Function,
      default: () => {},
    },
    classes: {
      type: String,
      default: "",
    },
  },
  methods: {
    clicked(event) {
      this.$emit("clickAction", event);
    },
  },
};
</script>
