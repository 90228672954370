<template>
  <div>
    <div class="md:content-container flex flex-col gap-6 md:gap-14 pt-4">
      <div class="content-container md:!px-0 w-full">
        <BrComponent component="top-row/container" />
      </div>

      <div class="flex flex-col md:flex-row gap-6 md:gap-10">
        <BrComponent
          class="w-full md:w-4/12 lg:3/12"
          component="left-column/container"
        />
        <BrComponent
          class="w-full md:w-8/12 lg:w-9/12 overflow-hidden"
          component="right-column/container"
        />
      </div>
      <BrComponent component="bottom-row/container" />
    </div>
  </div>
</template>

<script>
import { BrComponent } from "@bloomreach/vue-sdk";
import browsingPageMetas from "~/mixins/browsingPageMetas";

export default {
  name: "HomePage",
  components: { BrComponent },
  mixins: [browsingPageMetas],
  head() {
    return {
      meta: this.getMetaData(),
      script: this.$t("pages.home.schema"),
      link: [{ hid: "i18n-can", rel: "canonical", href: this.getUrl() }],
    };
  },
  methods: {
    getMetaData() {
      const meta = [];

      this.$config.fbDomainVerifyMetaEnabled &&
        meta.push(this.$t("pages.home.meta.facebook"));
      this.$config.pinterestDomainVerifyMetaEnabled &&
        meta.push(this.$t("pages.home.meta.pinterest"));

      return meta;
    },
  },
};
</script>

<style lang="postcss" scoped></style>
