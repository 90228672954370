<template>
  <div
    v-if="data"
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container flex flex-wrap justify-center">
      <div v-for="(item, index) in data" :key="index" class="feature-item">
        <div
          v-if="item.title !== '' && item.body.value !== ''"
          class="feature-item-inner"
        >
          <h3 class="font-bold text-size-7 text-blue mb-3">
            {{ item.title }}
          </h3>
          <div class="text-size-4 md:text-size-5" v-html="item.body.value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.gridFeatureItem ??
    null
);

// Deconstruct computed style properties from composable
const { wrapperClasses } = globalComponentProperties(properties, props.page);
</script>

<style lang="postcss" scoped>
.feature-item {
  @apply px-2 md:px-6 w-full md:w-1/2;

  &--full {
    @apply w-full;
  }

  &:nth-child(1) {
    .feature-item-inner {
      @apply border-t-1;
    }
  }

  @screen md {
    &:nth-child(-n + 2) {
      .feature-item-inner {
        @apply border-t-1;
      }
    }
  }

  &-inner {
    @apply border-b-1 border-grey-3 py-6 h-full;
  }

  :deep(a) {
    @apply text-blue;
  }
}
</style>
