<template>
  <SignPostBanner
    :show-arrows="showArrows"
    :title="title"
    :subtitle="subTitle"
    :banner-background-colour="bgColour"
    :banner-content-colour="contentColour"
    :alt-background-colour="altBgColour"
    :link="link"
    :image="image"
  />
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import SignPostBanner from "~/components/UI/banners/SignPostBanner.vue";
import { getDeviceImages } from "~/utils/bloomreach/images";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(() =>
  getContainerItemContent(props.component, props.page)
);
const image = computed(() => getDeviceImages(data.value.backgroundImage));
const title = computed(() => data.value.title);
const subTitle = computed(() => data.value.subTitle);
const link = computed(() => data.value.link);

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());
const showArrows = computed(() => properties.value.showArrows);
const bgColour = computed(() => properties.value.innerBackgroundColour);
const altBgColour = computed(() => properties.value.altBackgroundColour);
// Deconstruct computed style properties from composable
const { contentColour } = globalComponentProperties(properties, props.page);
</script>
