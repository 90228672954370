
import Vue, { PropType } from "vue";

export interface Images {
  mobileSrc: string;
  tabletSrc: string;
  desktopSrc: string;
}

export interface ImageHeight {
  desktop: string;
  tablet: string;
  mobile: string;
}

export default Vue.extend({
  name: "ResponsiveImage",
  components: {},
  props: {
    images: {
      type: Object as PropType<Images>,
      required: true,
      default: () => ({
        mobileSrc: "",
        tabletSrc: "",
        desktopSrc: "",
      }),
    },
    classes: {
      type: String,
      required: false,
      default: "",
    },
    altTag: {
      type: String,
      required: true,
    },
    imageHeight: {
      type: Object as PropType<ImageHeight>,
      required: false,
      default: () => {},
    },
    lazyLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    imageHeightClasses(): string {
      return this.imageHeight
        ? `image-height-mobile image-height-tablet image-height-desktop z-10 relative`
        : "";
    },
    imageHeights(): object | string {
      return this.imageHeight
        ? {
            "--mobile-image-height": this.imageHeight.mobile,
            "--tablet-image-height": this.imageHeight.tablet,
            "--desktop-image-height": this.imageHeight.desktop,
          }
        : "";
    },
  },
});
