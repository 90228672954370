
import Vue from "vue";
import ProductRecommendations from "~/components/product-recommendations/ProductRecommendations.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

export default Vue.extend({
  name: "VersionOne",
  components: { ProductRecommendations, DefaultButton, ResponsiveImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
    pathways: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    lazyLoadImage: {
      type: Boolean,
      default: () => true,
    },
    images: {
      type: Object,
      required: true,
    },
  },
});
