
import Vue from "vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import InputFields from "~/components/calculators/tile-calculator/inputs/InputFields.vue";
import FormElement from "~/components/UI/forms/Form.vue";
import FormCheckbox from "~/components/UI/forms/FormCheckbox.vue";

interface TileDimensions {
  width: string;
  height: string;
  m2: string;
}

interface WallOrFloorDimension {
  width: string;
  height: string;
  m2: string;
}

export default Vue.extend({
  name: "TileCalculator",
  components: {
    DefaultButton,
    InputFields,
    FormElement,
    FormCheckbox,
  },
  data() {
    return {
      resetFormKey: 0 as number,
      addWastage: true as boolean,
      tileDimensions: {
        width: "",
        height: "",
        m2: "",
      } as TileDimensions,
      wallOrFloorDimensions: [
        {
          width: "",
          height: "",
          m2: "",
        },
      ] as WallOrFloorDimension[],
      result: "",
    };
  },
  methods: {
    addWallOrFloor(): void {
      this.wallOrFloorDimensions.push({
        width: "",
        height: "",
        m2: "",
      });
    },
    removeWallOrFloor(): void {
      this.wallOrFloorDimensions.pop();
    },
    calculateTiles(): void {
      // Convert tile dimensions to square meters
      const tileArea = this.tileDimensions.m2
        ? Number(this.tileDimensions.m2)
        : (Number(this.tileDimensions.width) / 1000) *
          (Number(this.tileDimensions.height) / 1000);

      // Calculate the total area of all walls
      const totalWallArea = this.wallOrFloorDimensions.reduce((total, wall) => {
        const wallArea = wall.m2
          ? Number(wall.m2) * Number(wall.m2)
          : Number(wall.width) * Number(wall.height);
        return total + wallArea;
      }, 0);

      // Calculate the number of tiles needed
      let tileCount = totalWallArea / tileArea;
      if (this.addWastage) {
        tileCount *= 1.1; // Add 10% wastage
      }
      this.result = Math.ceil(tileCount).toString(); // Round up to the nearest whole number of tiles
      this.scrollToResults();
    },
    resetCalculator(): void {
      this.result = "";
      this.addWastage = true;
      this.tileDimensions = {
        width: "",
        height: "",
        m2: "",
      } as TileDimensions;
      this.wallOrFloorDimensions = [
        {
          width: "",
          height: "",
          m2: "",
        },
      ] as WallOrFloorDimension[];
      this.resetFormKey++;
      this.scrollToCalculator();
    },
    async scrollToResults(): Promise<void> {
      await this.$nextTick();
      const results = (this as any).$refs.tileResults.offsetTop;
      window.scrollTo({ top: results, behavior: "smooth" });
    },
    scrollToCalculator(): void {
      const calculator = (this as any).$refs.tileCalculator.offsetTop;
      window.scrollTo({ top: calculator, behavior: "smooth" });
    },
  },
});
