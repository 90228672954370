
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";
import { BreadCrumbsItems } from "./BreadCrumbsItems";
import BreadCrumbItem from "./BreadCrumbItem.vue";
import { ChevronIcon, HomeIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "BreadCrumbs",
  components: {
    LazyHydrate,
    BreadCrumbItem,
    ChevronIcon,
    HomeIcon,
  },
  props: {
    items: {
      type: Array as PropType<BreadCrumbsItems[]>,
      default: () => [],
    },
    bgGrey: {
      type: Boolean,
      default: false,
    },
  },
  head(): object {
    const json = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@type": "WebPage",
            "@id": this.$store.getters.domain,
            name: "Home",
          },
        },
      ],
    };

    this.items?.forEach((item: BreadCrumbsItems, index: number) => {
      json.itemListElement.push({
        "@type": "ListItem",
        position: index + 2,
        item: {
          "@type": "WebPage",
          "@id": `${this.$store.getters.domain}${
            this.items.length === index + 1
              ? this.$route.path
              : (item.to ?? this.$store.getters.domain)
          }`,
          name: item.title,
        },
      });
    });

    return {
      script: [
        {
          type: "application/ld+json",
          json,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("breadcrumbs", ["getBreadcrumbsItems"]),
    getClasses(): string {
      return `${this.bgGrey ? "bg-grey-2" : "bg-transparent"} ${
        this.hideOnMobile ? "hidden lg:block" : ""
      }`;
    },
    getBreadcrumbClasses() {
      if (this.getBreadcrumbsItems) {
        return this.hideOnMobile
          ? "lg:min-h-[51px]"
          : "min-h-[43px] md:min-h-[51px]";
      }
      return "";
    },
    hideOnMobile() {
      return [
        { name: "newsArticlePage", regex: "/news/article/.*" },
        { name: "trolleyPage", regex: "/trolley" },
      ].find((item) => this.$route.path.match(item.regex));
    },
    lastItem(): BreadCrumbsItems | undefined {
      return this.items ? this.items[this.items.length - 2] : undefined;
    },
    breadcrumbs() {
      if (!this.items) return [];

      if (this.items?.length <= 4) {
        return this.items.map((item, index, arr) => ({
          ...item,
          visible: true,
          to: index === arr.length - 1 ? null : item.to,
        }));
      }

      const firstItem = { ...this.items[0], visible: true };

      const hiddenItems = {
        visible: false,
        items: [...this.items].slice(1, -2),
      };

      const remainingItems = this.items.slice(-2).map((item, index, arr) => {
        return {
          ...item,
          visible: true,
          to: index === arr.length - 1 ? null : item.to,
        };
      });

      return [firstItem, hiddenItems, ...remainingItems];
    },
  },
});
