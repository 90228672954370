<template>
  <div>
    <Error
      v-if="!$fetchState.pending && isEmpty(page)"
      bloomreach-doc-path="404"
    />
    <!-- FYI - We have to import the breadcrumb component directly into this component to avoid hydration errors due to only having access to the fetch hook -->
    <BreadCrumbs v-if="breadcrumbs.length" :items="breadcrumbs" />
    <BrPage
      v-if="!$fetchState.pending && !$fetchState.error && !isEmpty(page)"
      :configuration="configuration"
      :mapping="mapping"
      :page="page"
    >
      <template #default="props">
        <component :is="getLayoutComponent(props)" :meta-data="pageMetaData" />
        <PreviewBanner
          :show-banner="isPreview"
          :page-title="pageMetaData.displayName"
        />
      </template>
    </BrPage>

    <LoadingSpinner
      v-if="$fetchState.pending"
      size="xxl"
      extra-classes="fixed z-50 w-full h-full bg-white opacity-80 top-0 left-0"
    >
    </LoadingSpinner>
  </div>
</template>

<script>
import { initialize } from "@bloomreach/spa-sdk";
import { BrPage } from "@bloomreach/vue-sdk";
import { isEmpty } from "ramda";
import Error from "~/components/shared/error.vue";
import browsingPageMetas from "~/mixins/browsingPageMetas";
import { timestampToISO } from "~/utils/date";
import { BreadCrumbsMutation } from "~/store/breadcrumbs";
import BreadCrumbs from "~/components/UI/breadcrumbs/BreadCrumbs.vue";
import PreviewBanner from "~/components/bloomreach/BloomreachPreviewBanner.vue";
import { fetchBrExperiencePage } from "~/services/bloomreach.service";
import { fetchTaxonomyById } from "~/services/taxonomies";
import { prefixCategory } from "~/lib/shared";
import { pageType, PageTypes } from "~/utils/pageTypes";
import LoadingSpinner from "~/components/UI/loading/LoadingSpinner.vue";

// Bloomreach Standard Components
import HeroBanner from "~/components/bloomreach/hero-banner/HeroBanner.vue";
import TitleParagraphImageButton from "~/components/bloomreach/title-paragraph-image-button/TitleParagraphImageButton.vue";
import MultiImageAndInfo from "~/components/bloomreach/multi-image-and-info-dropdown/MultiImageAndInfo.vue";
import GridFeature from "~/components/bloomreach/grid-feature/GridFeature.vue";
import Accordion from "~/components/bloomreach/accordion/Accordion.vue";
import MultiTextAndImage from "~/components/bloomreach/multi-text-and-image/MultiTextAndImage.vue";
import MultiListLinkAndImage from "~/components/bloomreach/multi-list-link-and-image/MultiListLinkAndImage.vue";
import PathwaysAndRecommendations from "~/components/bloomreach/pathways-and-recommendations/PathwaysAndRecommendations.vue";
import PageNavigation from "~/components/bloomreach/page-navigation/PageNavigation.vue";
import AnchorLinks from "~/components/bloomreach/anchorLinks/AnchorLinks.vue";
import AnchorPoint from "~/components/bloomreach/anchorLinks/AnchorPoint.vue";
import ProductComparison from "~/components/bloomreach/product-comparison/ProductComparison.vue";
import PromoBlocks from "~/components/bloomreach/promo-blocks/PromoBlocks.vue";
import ContentCarousel from "~/components/bloomreach/content-carousel/ContentCarousel.vue";
import MultiColumnContent from "~/components/bloomreach/multi-column-content/MultiColumnContent.vue";
import TextAndMedia from "~/components/bloomreach/text-and-media/TextAndMedia.vue";
import SignPostBanner from "~/components/bloomreach/sign-post-banner/SignPostBanner.vue";
import PromoAndRecs from "~/components/bloomreach/promo-and-recs/PromoAndRecs.vue";
import Calculators from "~/components/bloomreach/calculators/Calculators.vue";
import BrandsListing from "~/components/brands/BrandsListing.vue";
import DepartmentsNavigation from "~/components/bloomreach/departments-navigation/DepartmentsNavigation.vue";

// To be removed when top level cat's are fully new style
import TopLevelLegacy from "~/components/bloomreach/top-level-legacy/TopLevelLegacy.vue";

// Bloomreach Layouts
import OneColumn from "~/components/bloomreach/layouts/OneColumn.vue";
import TwoRows from "~/components/bloomreach/layouts/TwoRows.vue";
import TopLevelCategory from "~/components/bloomreach/layouts/TopLevelCategory.vue";
import HomePageLayout from "~/components/bloomreach/layouts/HomePage.vue";

export default {
  name: "BloomreachPage",
  components: {
    LoadingSpinner,
    Error,
    BrPage,
    OneColumn,
    TwoRows,
    TopLevelCategory,
    HomePageLayout,
    BreadCrumbs,
    PreviewBanner,
  },
  mixins: [browsingPageMetas],
  props: {
    path: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      page: {},
      pageMetaData: {},
      breadcrumbs: [],
      isPreview: false,
    };
  },
  async fetch() {
    try {
      const isTopLevelCategory =
        pageType(this.$route.path) === PageTypes.Taxonomy;

      // TODO: remove dependency on taxonomy endpoint (maybe get the correct redirect URL from the mega-menu data?)
      if (isTopLevelCategory) {
        const { id: taxonomyId } = this.$route.params;

        const taxonomy = await fetchTaxonomyById(
          this.$axios,
          Number(taxonomyId)
        );

        const topLevelCategoryUrl = `/${taxonomy.taxonomy.slug}/${prefixCategory(taxonomyId)}`;
        const hasCategoryPrefix =
          this.$route.fullPath.startsWith("/categories");

        if (this.$route.path !== topLevelCategoryUrl && !hasCategoryPrefix) {
          return this.$nuxt.context.redirect(301, topLevelCategoryUrl);
        }
      }

      const page = await initialize(this.configuration);
      const pageMetaData = page.getDocument().model.data || {};
      const pageName = pageMetaData.displayName;
      const displayBreadcrumbs = !!pageMetaData?.breadcrumbs?.length;

      this.$store.commit(`breadcrumbs/${BreadCrumbsMutation.SetItems}`, null);
      if (displayBreadcrumbs) {
        this.breadcrumbs = [
          ...pageMetaData.breadcrumbs
            .filter((item) => item.title !== pageName)
            .map((item) => ({
              to: item.url,
              title: item.title,
            })),
          { title: pageName },
        ];
      }

      this.page = page;
      this.pageMetaData = pageMetaData;
      this.isPreview = page.isPreview() && !this.serverId;
    } catch (e) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = 404;
      } else {
        this.$nuxt.error({ statusCode: 404, message: "Post not found" });
      }
    }
  },
  head() {
    return {
      ...this.getHeadData(),
    };
  },
  computed: {
    mapping() {
      return {
        heroBanner: HeroBanner,
        titleParagraphImageButton: TitleParagraphImageButton,
        multiImageAndInfoDropdown: MultiImageAndInfo,
        gridFeature: GridFeature,
        accordion: Accordion,
        multiTextAndImage: MultiTextAndImage,
        multiListLinkAndImage: MultiListLinkAndImage,
        pathwaysAndRecommendations: PathwaysAndRecommendations,
        pageNavigation: PageNavigation,
        anchorLinks: AnchorLinks,
        anchorPoint: AnchorPoint,
        productComparison: ProductComparison,
        departmentsNavigation: DepartmentsNavigation,
        PromoBlocks,
        ContentCarousel,
        MultiColumnContent,
        TextAndMedia,
        SignPostBanner,
        PromoAndRecs,
        Calculators,
        BrandsListing,
        TopLevelLegacy,
      };
    },
    serverId() {
      return this.$route.query?.["server-id"];
    },
    configuration() {
      const path = this.path ? this.path : this.$route.params.pathMatch;

      const authorizationToken = this.$route.query.token;

      return {
        endpoint: this.$config.brxmEndpoint,
        endpointQueryParameter: "endpoint",
        path,
        debug: false,
        authorizationToken,
        serverId: this.serverId,
        NBRMode: true,
        httpClient: async (config) => await this.getExperiencePage(config),
      };
    },
    excludeFromIndex() {
      return this.pageMetaData.noindex;
    },
    getSchemaType() {
      switch (this.pageMetaData.contentType) {
        case "brxsaas:Article":
          return this.articleSchema;
        default:
          return {};
      }
    },
    articleSchema() {
      return {
        "@context": "https://schema.org/",
        "@type": "Article",
        headline:
          this.pageMetaData.articleSchema.headline || this.pageMetaData.title,
        description:
          this.pageMetaData.articleSchema.description || this.pageMetaData.text,
        image: {
          "@type": "ImageObject",
          url: this.pageMetaData.imageUrl,
        },
        datePublished: timestampToISO(
          this.pageMetaData.articleSchema.datePublished
        ),
        dateModified: timestampToISO(
          this.pageMetaData.articleSchema.dateModified
        ),
      };
    },
    metaTitle() {
      return this.pageMetaData?.title ?? "";
    },
    metaDescription() {
      return this.pageMetaData?.text ?? this.pageMetaData?.description ?? "";
    },
  },
  methods: {
    isEmpty,
    async getExperiencePage(config) {
      const authorizationToken = this.$route.query.token;
      const insideExperienceManager = !!this.$route.query["server-id"];

      const getCachedPaged =
        config.method === "GET" &&
        !insideExperienceManager &&
        !authorizationToken;

      return await fetchBrExperiencePage(config, getCachedPaged);
    },
    getLayoutComponent(props) {
      switch (props.component.model.name) {
        case "one-column":
          return "OneColumn";
        case "two-rows":
          return "TwoRows";
        case "top-level-category":
          return "TopLevelCategory";
        case "home-page":
          return "HomePageLayout";
        default:
          return "one-column";
      }
    },
    getHeadData() {
      return {
        title: this.metaTitle,
        meta: [
          {
            hid: "robots",
            name: "robots",
            content: this.excludeFromIndex
              ? "noindex, nofollow"
              : "index, follow",
          },
          {
            hid: "description",
            name: "description",
            content: this.metaDescription,
          },
          {
            hid: "og:title",
            property: "og:title",
            content: this.metaTitle,
          },
          {
            hid: "og:description",
            property: "og:descripion",
            content: this.metaDescription,
          },
          {
            hid: "og:url",
            property: "og:url",
            content: this.getPageUrl(),
          },
        ],
        script: [
          {
            type: "application/ld+json",
            json: this.page ? this.getSchemaType : {},
          },
        ],
      };
    },
  },
};
</script>
