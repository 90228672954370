
import Vue from "vue";

export default Vue.extend({
  name: "FormSelectOption",
  props: {
    value: { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
  },
});
