
import Vue, { PropType } from "vue";
import TopLevelDepartmentSubTaxonomyCardList from "./TopLevelDepartmentSubTaxonomyCardList.vue";
import { sortAs } from "~/lib/taxonomies";
export default Vue.extend({
  components: { TopLevelDepartmentSubTaxonomyCardList },
  props: {
    taxonomy: Array,
    sortBy: String as PropType<string>,
    rootSlug: String as PropType<string>,
  },
  methods: {
    sortAs,
  },
});
