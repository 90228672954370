import { computed, ComputedRef } from "vue";
import {
  ComponentParameters,
  GlobalComponentProperties,
} from "./globalComponentProperties.d";
import { transform } from "~/lib/tailwind";
import "@bloomreach/spa-sdk";

export const globalComponentProperties = (
  properties: ComputedRef<ComponentParameters>,
  page = null as any
): GlobalComponentProperties => {
  const wrapperClasses = computed(() => {
    const classes = [];
    const styles = [];
    if (properties.value?.noBottomPadding) classes.push("!pb-0");
    if (properties.value?.noTopPadding) classes.push("!pt-0");
    if (properties.value?.reducedTopPadding) classes.push("!pt-10");
    if (properties.value?.reducedBottomPadding) classes.push("!pb-10");
    styles.push(bgColour.value);
    return { classes: classes.join(" "), styles: styles.join(" ") };
  });

  const innerWrapperClasses = computed(() => {
    const classes = ["px-4"];
    const styles = [];

    if (properties.value?.reducedWidth)
      classes.push("px-4 md:px-[12rem] lg:px-[16rem]");

    if (bgColour.value !== innerBgColour.value)
      classes.push("!py-4 md:!py-6 !px-4 md:!px-6");

    styles.push(innerBgColour.value);

    return { classes: classes.join(" "), styles: styles.join(" ") };
  });

  // Take properties that should be transformed to a tailwind class
  // e.g. "object fit" as "cover" in human terms, should become object-cover in tailwind terms
  const tailwindTransformers = computed(() => transform(properties.value));

  const { primaryColour, secondaryColour, tertiaryColour } = page?.getDocument()
    .model.data ?? {
    primaryColour: "#ffff",
    secondaryColour: "#ffff",
    tertiaryColour: "#ffff",
  };

  const getColourValue = (
    colourKey: string | undefined,
    customColours: Record<string, string>,
    defaultColour: string
  ) => {
    switch (colourKey) {
      case "none":
        return "";
      case "white":
        return "#fff";
      case "light grey":
        return "#f4f4f4";
      case "dark":
        return "#1a202c";
      case "blue":
        return "#065CAB";
      case "black":
        return "#000000";
      case "grey":
        return "#D9D9D9";
      case "grey 05":
        return "#666666";
      case "red":
        return "#E52228";
      case "orange":
        return "#BD5B00";
      case "green":
        return "#238826";
      case "yellow":
        return "#FEE200";
      case "primary colour":
        return customColours["primary colour"];
      case "secondary colour":
        return customColours["secondary colour"];
      case "tertiary colour":
        return customColours["tertiary colour"];
      default:
        return defaultColour;
    }
  };

  const customColours = (fallback?: string) => ({
    "primary colour": primaryColour || fallback,
    "secondary colour": secondaryColour || fallback,
    "tertiary colour": tertiaryColour || fallback,
  });

  const innerBgColour = computed(() => {
    const colourKey = properties.value?.innerBackgroundColour;
    return colourKey
      ? `background: ${getColourValue(colourKey, customColours("#fff"), "")}`
      : "";
  });

  const bgColour = computed(() => {
    const colourKey = properties.value?.backgroundColour;
    return `background: ${getColourValue(colourKey, customColours(), "#fff")}`;
  });

  const itemBackgroundColour = computed(() => {
    const colourKey = properties.value?.itemBackgroundColour;
    return getColourValue(colourKey, customColours(), "#1a202c");
  });

  const slideIndicatorColour = computed(() => {
    const colourKey = properties.value?.slideIndicatorColour;
    return getColourValue(colourKey, customColours(), "");
  });

  const titleTextColour = computed(() => {
    const colourKey = properties.value?.titleTextColour;
    return colourKey
      ? `color: ${getColourValue(colourKey, customColours(), "")}`
      : "";
  });

  const bodyTextColour = computed(() => {
    const colourKey = properties.value?.bodyTextColour;
    return `color: ${getColourValue(colourKey, customColours(), "#1a202c")}`;
  });

  const contentColour = computed(() => {
    const colourKey = properties.value?.contentColour;
    return getColourValue(colourKey, customColours(), "#1a202c");
  });

  const buttonColour = computed(() => {
    const colourKey = properties.value?.buttonColour;
    if (colourKey === "white") {
      return `background: #fff; color: ${primaryColour}`;
    }
    return `background: ${getColourValue(colourKey, customColours(), "#065CAB")}`;
  });

  const ctaBtnColour = computed(() => {
    const colourKey = properties.value?.ctaBtnColour;
    if (colourKey === "white") {
      return "background: #fff; color: #1a202c";
    }
    return `background: ${getColourValue(colourKey, customColours(), contentColour.value)}`;
  });

  const ctaTextColour = computed(() => {
    const colourKey = properties.value?.ctaTextColour;
    return `color: ${getColourValue(colourKey, customColours(), "")}`;
  });

  const borderColour = computed(() => {
    const colourKey = properties.value?.borderColour;
    const colourValue = getColourValue(colourKey, customColours(), "");
    return colourValue ? `border: 1px solid ${colourValue}` : "";
  });

  const bulletColour = computed(() => {
    const colourKey = properties.value?.bulletColour;
    return `background: ${getColourValue(colourKey, customColours(), secondaryColour)}`;
  });

  const titleAlignment = computed(() => {
    switch (properties.value?.titleAlignment) {
      case "left":
        return "text-left";
      case "center":
        return "text-center";
      case "right":
        return "text-end";
      default:
        return "";
    }
  });

  const bodyAlignment = computed(() => {
    switch (properties.value?.bodyAlignment) {
      case "left":
        return "text-left";
      case "center":
        return "text-center";
      case "right":
        return "text-end";
      default:
        return "";
    }
  });

  const imageAlignment = computed(() => {
    switch (properties.value?.imageAlignment) {
      case "left":
        return "mr-auto";
      case "center":
        return "mx-auto";
      case "right":
        return "ml-auto";
      default:
        return "";
    }
  });

  const contentAlignment = computed(() => {
    switch (properties.value?.contentAlignment) {
      case "top left":
        return { parent: "justify-start", children: "justify-start text-left" };
      case "bottom left":
        return { parent: "justify-start", children: "justify-end text-left" };
      case "top right":
        return { parent: "justify-end", children: "justify-start text-left" };
      case "bottom right":
        return { parent: "justify-end", children: "justify-end text-left" };
      case "top center":
        return {
          parent: "justify-center",
          children: "justify-start text-left",
        };
      case "bottom center":
        return { parent: "justify-center", children: "justify-end text-left" };
      case "center":
        return {
          parent: "justify-center",
          children: "justify-center items-center text-center",
        };
      default:
        return { parent: "", children: "" };
    }
  });

  const height = computed(() => {
    switch (properties.value?.height) {
      case "small":
        return "h-[125px] md:h-[125px]";
      case "medium":
        return "h-[250px] md:h-[300px]";
      case "large":
        return "h-[250px] md:h-[500px]";
      default:
        return "";
    }
  });

  const bodyTextSize = computed(() => {
    switch (properties.value?.bodyTextSize) {
      case "small":
        return "text-size-4";
      case "normal":
        return "text-size-5";
      default:
        return "text-size-5";
    }
  });

  const displayContent = computed(() => {
    switch (properties.value?.displayContent) {
      case "all":
        return "";
      case "desktop":
        return "hidden lg:flex";
      case "tablet and desktop":
        return "hidden md:flex";
      case "mobile and tablet":
        return "lg:hidden";
      case "mobile":
        return "sm:hidden";
      default:
        return "";
    }
  });

  const headingType = computed(() => properties.value?.titleTag);

  return {
    wrapperClasses,
    innerWrapperClasses,
    tailwindTransformers,
    titleAlignment,
    bodyAlignment,
    contentAlignment,
    imageAlignment,
    bgColour,
    itemBackgroundColour,
    headingType,
    titleTextColour,
    bodyTextColour,
    contentColour,
    buttonColour,
    height,
    displayContent,
    borderColour,
    primaryColour,
    secondaryColour,
    tertiaryColour,
    ctaBtnColour,
    innerBgColour,
    bulletColour,
    ctaTextColour,
    slideIndicatorColour,
    bodyTextSize,
  };
};
