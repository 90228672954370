<template>
  <div class="mb-10">
    <!-- Marketing carousel -->
    <client-only v-if="!properties.excludeLegacyBanners">
      <TopLevelDepartmentCarousel
        v-if="carouselItems.length"
        :carousel-items="carouselItems"
      />
      <TopLevelDepartmentCarouselMobile
        v-if="carouselItems.length"
        :carousel-items="carouselItems"
      />
    </client-only>

    <!-- Sort by filter -->
    <div class="hidden lg:flex justify-end gap-1 pl-5 mb-4">
      <span>{{ $t("search.sortBy") }}</span>
      <div class="bg-white relative pl-1">
        <select
          id="sort-by"
          v-model="sortBy"
          name="sort-by"
          class="w-full h-full cursor-pointer appearance-none mr-6 outline-none font-semibold"
        >
          <option value="atoz">{{ $t("sortBy.a2z") }}</option>
          <option value="popularity" selected>
            {{ $t("sortBy.popularity") }}
          </option>
        </select>

        <ChevronIcon
          classes="h-4 w-4 pointer-events-none absolute top-1 right-1"
          direction="down"
        />
      </div>
    </div>

    <!-- Sub-taxonomies -->
    <TopLevelDepartmentSubTaxonomyCards
      :taxonomy="taxonomy?.children ?? []"
      :root-slug="taxonomy?.taxonomy?.slug ?? ''"
      :sort-by="sortBy"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { fetchTaxonomyById } from "~/services/taxonomies";
import { fetchPageArticle } from "~/services/content.service";
import TopLevelDepartmentCarousel from "~/components/bloomreach/top-level-legacy/TopLevelDepartmentCarousel.vue";
import TopLevelDepartmentCarouselMobile from "~/components/bloomreach/top-level-legacy/TopLevelDepartmentCarouselMobile.vue";
import TopLevelDepartmentSubTaxonomyCards from "~/components/bloomreach/top-level-legacy/TopLevelDepartmentSubTaxonomyCards.vue";
import { ChevronIcon } from "~/components/UI/icons";

export default Vue.extend({
  components: {
    TopLevelDepartmentCarousel,
    TopLevelDepartmentCarouselMobile,
    TopLevelDepartmentSubTaxonomyCards,
    ChevronIcon,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      carouselItems: [],
      taxonomy: {},
      sortBy: "popularity",
    };
  },
  async fetch() {
    const { id: taxonomyId } = this.$route.params;

    const taxonomy = await fetchTaxonomyById(this.$axios, Number(taxonomyId));

    this.carouselItems = await fetchPageArticle(
      this.$axios,
      taxonomy?.taxonomy?.slug ?? ""
    )
      .then((page) => page?.meta?.carousel_image)
      .catch(() => []);

    this.taxonomy = taxonomy;
  },
  computed: {
    properties() {
      return this.component.getParameters();
    },
  },
});
</script>
