<template>
  <div
    v-if="data"
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container">
      <LozengeLinks
        :links="links"
        :is-anchor-links="true"
        :is-carousel="properties.isCarousel"
        :lozenge-styles="lozengeStyles"
      />
    </div>
  </div>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import LozengeLinks from "~/components/UI/links/LozengeLinks.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});
// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());
// Return component data fields from bloomreach SDK
const data = computed(
  () => getContainerItemContent(props.component, props.page)?.anchorLink ?? null
);

const links = data.value.map((link) => {
  return { href: link.anchorLink, name: link.anchorLinkName };
});

const getColour = (colour) => {
  switch (colour) {
    case "white":
      return "#ffff";
    case "dark":
      return "#1a202c";
    case "blue":
      return "#065CAB";
    case "primary colour":
      return `${primaryColour || "#ffff"}`;
    case "secondary colour":
      return `${secondaryColour || "#ffff"}`;
    case "tertiary colour":
      return `${tertiaryColour || "#ffff"}`;
    default:
      return "";
  }
};

const lozengeStyles = computed(() => {
  return {
    "--background-color": getColour(properties.value.lozengeColour),
    "--color": getColour(properties.value.lozengeTextColour),
    "--background-color--hover": getColour(
      properties.value.lozengeHoverStateColour
    ),
    "--color-hover": getColour(properties.value.lozengeHoverStateTextColour),
  };
});

// Deconstruct computed style properties from composable
const { wrapperClasses, primaryColour, secondaryColour, tertiaryColour } =
  globalComponentProperties(properties, props.page);
</script>
