<template>
  <div
    v-if="data"
    class="w-full relative py-14 sm:py-23 bg-white"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div
      class="w-full h-full flex flex-col justify-center text-left content-container font-gilroy"
      :class="innerWrapperClasses.classes"
      :style="innerWrapperClasses.styles"
    >
      <component :is="getCalculatorType()" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import BtusCalculator from "~/components/calculators/btu-calculator/BtuCalculator.vue";
import TileCalculator from "~/components/calculators/tile-calculator/TileCalculator.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(
  () => getContainerItemContent(props.component, props.page) ?? null
);
// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Deconstruct computed style properties from composable
const { wrapperClasses, innerWrapperClasses } = globalComponentProperties(
  properties,
  props.page
);
function getCalculatorType() {
  switch (data.value.type?.selectionValues[0].key) {
    case "BTU Calculator":
      return BtusCalculator;
    case "Tile Calculator":
      return TileCalculator;
    default:
      return BtusCalculator;
  }
}
</script>
