import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { handleError } from "./helpers";
import { getBrands } from "~/services/api/brands.api";
import { BrandGroup, BrandsData } from "~/services/brands.d";

export const fetchBrands = async ($axios: NuxtAxiosInstance): Promise<any> => {
  try {
    return await getBrands($axios)
      .then((response) => response?.data)
      .then((res) => transformBrandsData(res));
  } catch (error) {
    return handleError(error);
  }
};

export function transformBrandsData(json: BrandsData): BrandGroup[] {
  return Object.entries(json)
    .reduce<BrandGroup[]>((brandsArray, [name, slug]) => {
      const firstChar: string = name.charAt(0);

      // Determines the target index based on the first character
      const targetIndex: number = !isNaN(parseInt(firstChar))
        ? 36
        : firstChar.toUpperCase().charCodeAt(0) - 65;

      // Initialise or get the target group
      const targetGroup: BrandGroup = brandsArray[targetIndex] || {
        title: !isNaN(parseInt(firstChar)) ? "0-9" : firstChar.toUpperCase(),
        brands: [],
      };

      // Add the brand to the target group
      targetGroup.brands.push({ name, slug });

      // Sort brands alphabetically
      targetGroup.brands.sort((a, b) => a.name.localeCompare(b.name));

      // Update the brands array with the modified target group
      brandsArray[targetIndex] = targetGroup;

      return brandsArray;
    }, [])
    .filter((group) => group);
}
