
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "BodyText",
  props: {
    text: {
      type: String as PropType<string>,
      required: true,
    },
    readMore: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    bodyTextSize: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    readMoreClasses(): Array<string> {
      const classes = ["truncate-text"];

      if (this.readMore && !this.isOpen) {
        classes.push("truncate-text--active");
        classes.push(
          this.bodyTextSize === "text-size-4" ? "h-[4rem]" : "md:h-[5rem]"
        );
      }
      return classes;
    },
  },
  methods: {
    toggleState(): void {
      this.isOpen = !this.isOpen;
    },
  },
});
