
import Vue, { PropType } from "vue";
import ProductRecommendations from "~/components/product-recommendations/ProductRecommendations.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

interface HeadingTypes {
  heading: String;
  subHeading: String;
}

export default Vue.extend({
  name: "VersionOne",
  components: { ProductRecommendations, DefaultButton, ResponsiveImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeImgOverlay: {
      type: Boolean,
      default: false,
    },
    pathways: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    lazyLoadImage: {
      type: Boolean,
      default: () => true,
    },
    contentColour: {
      type: String,
      default: "",
      required: true,
    },
    headingTypes: {
      type: Object as PropType<HeadingTypes>,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pathwaysData() {
      return this.pathways || {};
    },
    ctaHref() {
      return this.data.cta?.href;
    },
    ctaText() {
      return this.data.cta?.text;
    },
  },
  methods: {
    getHeadingClass(heading: string) {
      switch (heading) {
        case "H1":
          return "heading-one";
        case "H2":
          return "heading-two";
        case "H3":
          return "heading-three";
        case "H4":
          return "heading-four";
        case "H5":
          return "heading-five";
        case "H6":
          return "heading-six";
        default:
          return "heading-two";
      }
    },
  },
});
