
import Vue from "vue";
import FormInput from "~/components/UI/forms/FormInput.vue";
import FormLabel from "~/components/UI/forms/FormLabel.vue";

interface Inputs {
  width: string;
  height: string;
  m2: string;
}

export default Vue.extend({
  components: { FormInput, FormLabel },
  data(): { inputs: Inputs } {
    return {
      inputs: {
        width: "",
        height: "",
        m2: "",
      },
    };
  },
  watch: {
    inputs: {
      handler() {
        this.update();
      },
      deep: true,
    },
  },
  methods: {
    clearFields(value: string, type = "click"): void {
      switch (value) {
        case "width":
        case "height":
          this.inputs.m2 = "";
          break;
        case "m2":
          if (type === "blur") {
            if (this.inputs.m2 !== "") {
              this.inputs.width = "";
              this.inputs.height = "";
            }
          } else {
            this.inputs.width = "";
            this.inputs.height = "";
          }
          break;
      }
    },
    update(): void {
      const { width, height, m2 } = this.inputs;
      this.$emit("update", {
        width,
        height,
        m2,
      });
    },
    checkRequiredState(type: string): boolean | undefined {
      const { width, height, m2 } = this.inputs;
      if (type === "width" || type === "height") {
        return width !== "" || height !== "" || m2 === "";
      }
      if (type === "m2") {
        return width === "" && height === "";
      }
    },
  },
});
