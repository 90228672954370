<template>
  <div
    v-if="items.length"
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container" :class="[{ 'md:!px-0': removeXPadding }]">
      <ContentCarousel
        :items="items"
        :show-read-more="showReadMore"
        :slides-per-page="{
          desktop: itemsPerRowDesktop,
          tablet: itemsPerRowTablet,
          mobile: itemsPerRowMobile,
        }"
        :image-height="{
          mobile: imageHeightMobile,
          tablet: imageHeightTablet,
          desktop: imageHeightDesktop,
        }"
        :content-colour="contentColour"
        :body-colour="bodyTextColour"
        :background-colour="itemBackgroundColour"
        :border-colour="borderColour"
        :cta-btn-colour="ctaBtnColour"
        :cta-text-colour="ctaTextColour"
        :image-is-icon="imageIsIcon"
        :center-items="centerItems"
        :show-carousel-arrows="showCarouselArrows"
        :slide-indicator-colour="slideIndicatorColour"
        :arrow-style="arrowStyle"
      />
    </div>
  </div>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed, ref } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import ContentCarousel from "~/components/UI/carousel/ContentCarousel/ContentCarousel.vue";
import { getDeviceImages } from "~/utils/bloomreach/images";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

const removeXPadding = ref(props.page.root.model.name === "top-level-category");

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.contentCarouselItem ??
    null
);

const items = computed(() => {
  if (!data.value.length) return [];

  return data.value.map((item) => {
    return {
      title: item.title,
      body: item.body.value,
      images: getDeviceImages(item.image),
      cta: item.cta,
    };
  });
});

const showReadMore = computed(() => {
  return properties.value.showReadMore;
});

const imageIsIcon = computed(() => {
  return properties.value.imageIsIcon;
});

const centerItems = computed(() => {
  return properties.value.centerItems;
});

const showCarouselArrows = computed(() => {
  return properties.value.showCarouselArrows;
});

const imageHeightMobile = computed(() => {
  return getImageHeight(properties.value.imageHeightMobile);
});

const imageHeightTablet = computed(() => {
  return getImageHeight(properties.value.imageHeightTablet);
});

const imageHeightDesktop = computed(() => {
  return getImageHeight(properties.value.imageHeightDesktop);
});

const itemsPerRowDesktop = computed(() => {
  return Number(properties.value.itemsPerRowDesktop);
});

const itemsPerRowTablet = computed(() => {
  return Number(properties.value.itemsPerRowTablet);
});

const itemsPerRowMobile = computed(() => {
  return Number(properties.value.itemsPerRowMobile);
});

const arrowStyle = computed(() => {
  return properties.value.slideIndicatorColour;
});

const getImageHeight = (val) => {
  switch (val) {
    case "extra small":
      return "125px";
    case "small":
      return "200px";
    case "medium":
      return "275px";
    case "large":
      return "375px";
    default:
      return "275px";
  }
};

// Deconstruct computed style properties from composable
const {
  wrapperClasses,
  contentColour,
  bodyTextColour,
  itemBackgroundColour,
  borderColour,
  ctaBtnColour,
  ctaTextColour,
  slideIndicatorColour,
} = globalComponentProperties(properties, props.page);
</script>

<style lang="postcss"></style>
