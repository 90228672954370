var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.items.length)?_c('div',{staticClass:"w-full relative py-16 sm:py-23 font-gilroy",class:_setup.wrapperClasses.classes,style:(_setup.wrapperClasses.styles)},[_c('div',{staticClass:"content-container",class:[{ 'md:!px-0': _setup.removeXPadding }]},[_c(_setup.ContentCarousel,{attrs:{"items":_setup.items,"show-read-more":_setup.showReadMore,"slides-per-page":{
        desktop: _setup.itemsPerRowDesktop,
        tablet: _setup.itemsPerRowTablet,
        mobile: _setup.itemsPerRowMobile,
      },"image-height":{
        mobile: _setup.imageHeightMobile,
        tablet: _setup.imageHeightTablet,
        desktop: _setup.imageHeightDesktop,
      },"content-colour":_setup.contentColour,"body-colour":_setup.bodyTextColour,"background-colour":_setup.itemBackgroundColour,"border-colour":_setup.borderColour,"cta-btn-colour":_setup.ctaBtnColour,"cta-text-colour":_setup.ctaTextColour,"image-is-icon":_setup.imageIsIcon,"center-items":_setup.centerItems,"show-carousel-arrows":_setup.showCarouselArrows,"slide-indicator-colour":_setup.slideIndicatorColour,"arrow-style":_setup.arrowStyle}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }