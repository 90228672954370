<template>
  <div>
    <div class="content-container p-0 lg:!p-4 lg:!pt-0">
      <BrComponent component="top-row/container" />
    </div>
    <div class="md:content-container md:pt-4">
      <div class="grid grid-cols-12 md:gap-8">
        <BrComponent
          class="w-full col-span-12 lg:col-span-3"
          component="left-column/container"
        />
        <BrComponent
          class="w-full col-span-12 lg:col-span-9 overflow-hidden md:overflow-visible"
          component="right-column/container"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BrComponent } from "@bloomreach/vue-sdk";
export default {
  name: "TopLevelCategory",
  components: { BrComponent },
};
</script>

<style lang="postcss" scoped></style>
