
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    method: {
      type: String as PropType<"POST" | "GET" | "PUT" | "PATCH">,
      default: "GET",
    },
    action: {
      type: String as PropType<string>,
      default: "",
    },
    id: {
      type: String as PropType<string>,
      default: "",
    },
    classes: {
      type: String as PropType<string>,
      default: "",
    },
    checkForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dirty: false,
    };
  },
  watch: {
    checkForm(val) {
      if (val) {
        this.dirty = true;
      }
    },
  },
  methods: {
    bubble(event: SubmitEvent) {
      const form = event.target as HTMLFormElement;
      if (form.checkValidity()) {
        this.$emit("submit", event);
      } else {
        this.dirty = true;
        form.reportValidity();
      }
    },

    captureBubble() {
      const form = this.$refs.form as any as HTMLFormElement;
      this.$emit("update:validity", form?.checkValidity()); // use v-bind:validity.sync="xxx"
    },
  },
});
