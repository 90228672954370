
import Vue from "vue";
import { ChevronIcon } from "~/components/UI/icons";

export default Vue.extend({
  name: "BreadCrumbItem",
  components: {
    ChevronIcon,
  },
  props: {
    to: {
      type: String,
      default: "",
    },
    liClassList: {
      type: String,
      default: "",
    },
    linkClass: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    liClasses() {
      return [
        this.liClassList,
        this.mobile ? "flex lg:hidden w-full" : "hidden lg:flex",
        "items-center",
      ];
    },
    linkClasses() {
      return [
        this.linkClass,
        "hover:text-black-default",
        this.mobile ? "w-full" : "mr-2 ml-2",
      ];
    },
  },
});
