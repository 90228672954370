
import Vue from "vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import FormElement from "~/components/UI/forms/Form.vue";
import FormInput from "~/components/UI/forms/FormInput.vue";
import FormLabel from "~/components/UI/forms/FormLabel.vue";
import FormSelect from "~/components/UI/forms/FormSelect.vue";

export default Vue.extend({
  name: "BtuCalculator",
  components: {
    DefaultButton,
    FormInput,
    FormLabel,
    FormElement,
    FormSelect,
  },
  data() {
    return {
      resetFormKey: 0 as number,
      inputs: {
        roomType: "",
        dimensionType: "0",
        width: "",
        height: "",
        length: "",
        windowArea: "",
        outsideWallCount: "",
        outsideWallType: "",
        windowType: "",
        belowRoom: "",
        aboveRoom: "",
      },
      radioOptions: {
        roomTypes: [
          {
            id: "radio-livingroom",
            img: "https://iprospecthosting.com/toolstation/btu/assets/img/type_livingroom.svg",
            title: this.$t("btuCalculator.radioOptions.roomTypes.livingRoom"),
            value: "22",
          },
          {
            id: "radio-bathroom",
            img: "https://iprospecthosting.com/toolstation/btu/assets/img/type_bathroom.svg",
            title: this.$t("btuCalculator.radioOptions.roomTypes.bathroom"),
            value: "19",
          },
          {
            id: "radio-bedroom",
            img: "https://iprospecthosting.com/toolstation/btu/assets/img/type_bedroom.svg",
            title: this.$t("btuCalculator.radioOptions.roomTypes.bedroom"),
            value: "23",
          },
          {
            id: "radio-kitchen",
            img: "https://iprospecthosting.com/toolstation/btu/assets/img/type_kitchen.svg",
            title: this.$t("btuCalculator.radioOptions.roomTypes.kitchen"),
            value: "17",
          },
          {
            id: "radio-hallway",
            img: "https://iprospecthosting.com/toolstation/btu/assets/img/type_hallway.svg",
            title: this.$t("btuCalculator.radioOptions.roomTypes.hallway"),
            value: "17.0",
          },
        ],
        dimensionTypes: [
          {
            id: "radio-meters",
            title: this.$t("btuCalculator.radioOptions.dimensionTypes.m"),
            value: "0",
          },
          {
            id: "radio-feet",
            title: this.$t("btuCalculator.radioOptions.dimensionTypes.ft"),
            value: "1",
          },
        ],
      },
      selectOptions: {
        outsideWallCount: [
          {
            value: "1",
            text: this.$t("btuCalculator.selectOptions.outsideWallCount.one"),
          },
          {
            value: "2",
            text: this.$t("btuCalculator.selectOptions.outsideWallCount.two"),
          },
          {
            value: "3",
            text: this.$t("btuCalculator.selectOptions.outsideWallCount.three"),
          },
        ],
        outsideWallType: [
          {
            value: "1.5",
            text: this.$t(
              "btuCalculator.selectOptions.outsideWallType.brickCavity"
            ),
          },
          {
            value: "0.92",
            text: this.$t(
              "btuCalculator.selectOptions.outsideWallType.insulatedBrickCavity"
            ),
          },
          {
            value: "3",
            text: this.$t(
              "btuCalculator.selectOptions.outsideWallType.105SolidBrick"
            ),
          },
          {
            value: "2.1",
            text: this.$t(
              "btuCalculator.selectOptions.outsideWallType.220SolidBrick"
            ),
          },
          {
            value: "0.4",
            text: this.$t(
              "btuCalculator.selectOptions.outsideWallType.woodFrame"
            ),
          },
        ],
        windowType: [
          {
            value: "2.9",
            text: this.$t(
              "btuCalculator.selectOptions.windowType.woodPlasticDoubleGlazed"
            ),
          },
          {
            value: "4",
            text: this.$t(
              "btuCalculator.selectOptions.windowType.metalDoubleGlazed"
            ),
          },
          {
            value: "6.4",
            text: this.$t(
              "btuCalculator.selectOptions.windowType.metaSingleGlazed"
            ),
          },
          {
            value: "5",
            text: this.$t(
              "btuCalculator.selectOptions.windowType.woodSingleGlazed"
            ),
          },
        ],
        belowRoom: [
          {
            value: "1",
            text: this.$t("btuCalculator.selectOptions.belowRoom.heatedRoom"),
          },
          {
            value: "2",
            text: this.$t(
              "btuCalculator.selectOptions.belowRoom.soilUnderWoodFloor"
            ),
          },
          {
            value: "3",
            text: this.$t(
              "btuCalculator.selectOptions.belowRoom.soilUnderConcreteFloor"
            ),
          },
          {
            value: "4",
            text: this.$t(
              "btuCalculator.selectOptions.belowRoom.suspendedConcreteFloor"
            ),
          },
        ],
        aboveRoom: [
          {
            value: "0",
            text: this.$t("btuCalculator.selectOptions.aboveRoom.heatedRoom"),
          },
          {
            value: "2.7",
            text: this.$t(
              "btuCalculator.selectOptions.aboveRoom.pitchedUninsulatedRoof"
            ),
          },
          {
            value: "0.61",
            text: this.$t(
              "btuCalculator.selectOptions.aboveRoom.pitched50InsulatedRoof"
            ),
          },
          {
            value: "0.35",
            text: this.$t(
              "btuCalculator.selectOptions.aboveRoom.pitched100InsulatedRoof"
            ),
          },
          {
            value: "1.66",
            text: this.$t(
              "btuCalculator.selectOptions.aboveRoom.flatUninsulatedRoof"
            ),
          },
          {
            value: "0.5",
            text: this.$t(
              "btuCalculator.selectOptions.aboveRoom.flatInsulatedRoom"
            ),
          },
        ],
      },
      results: {
        watts: 0,
        btus: 0,
      },
    };
  },
  methods: {
    calculate(): void {
      if (this.inputs.roomType === "") {
        (this as any).$toast.error(this.$t("btuCalculator.errors.roomType"));
        this.scrollToCalculator();
        return;
      }
      const measurementType = parseFloat(this.inputs.dimensionType);
      const roomType = parseFloat(this.inputs.roomType);
      const windowArea = parseFloat(this.inputs.windowArea);
      const outsideWallCount = parseInt(this.inputs.outsideWallCount, 10);
      const outsideWallType = parseFloat(this.inputs.outsideWallType);
      const windowType = parseFloat(this.inputs.windowType);
      const belowRoom = parseInt(this.inputs.belowRoom, 10);
      const aboveRoom = parseFloat(this.inputs.aboveRoom);
      const height =
        parseFloat(this.inputs.height) / (measurementType === 1 ? 3.281 : 1);
      const width =
        parseFloat(this.inputs.width) / (measurementType === 1 ? 3.281 : 1);
      const length =
        parseFloat(this.inputs.length) / (measurementType === 1 ? 3.281 : 1);
      const floorarea = width * length;
      const ceilingloss = floorarea * aboveRoom;
      const windloss = windowArea * windowType;
      const wall1loss = (height * width - windowArea) * outsideWallType;
      const wall2loss =
        outsideWallCount > 1 ? height * length * outsideWallType : 0;
      const wall3loss =
        outsideWallCount > 2 ? height * width * outsideWallType : 0;
      let airloss = floorarea * height * 0.33;
      let floorloss = 0;
      let total;

      switch (belowRoom) {
        case 1:
          floorloss = 0;
          break;
        case 2:
          floorloss = floorarea > 16 ? 0.96 * floorarea : 1.27 * floorarea;
          break;
        case 3:
          if (floorarea > 16 && outsideWallCount === 1)
            floorloss = 0.45 * floorarea;
          else if (floorarea > 16 && outsideWallCount > 1)
            floorloss = 0.72 * floorarea;
          else if (floorarea <= 16 && outsideWallCount === 1)
            floorloss = 0.74 * floorarea;
          else if (floorarea <= 16 && outsideWallCount > 1)
            floorloss = 1.22 * floorarea;
          break;
        case 4:
          floorloss = 2.13 * floorarea;
          break;
      }

      switch (roomType) {
        case 17:
          airloss *= 1.5;
          break;
        case 19:
          airloss *= 0.5;
          break;
        case 22:
          airloss *= 1;
          break;
        case 23:
          airloss *= 2.0;
          break;
      }

      total =
        (windloss +
          wall1loss +
          wall2loss +
          wall3loss +
          ceilingloss +
          floorloss +
          airloss) *
        roomType;

      if (belowRoom === 1) total -= floorarea * 1.6 * 3;
      total *= 1.15;

      this.results.watts = Math.floor(total);
      this.results.btus = Math.floor(total * 3.412);
      this.scrollToResults();
    },
    resetCalculator(): void {
      this.inputs = {
        roomType: "",
        dimensionType: "0",
        width: "",
        height: "",
        length: "",
        windowArea: "",
        outsideWallCount: "",
        outsideWallType: "",
        windowType: "",
        belowRoom: "",
        aboveRoom: "",
      };
      this.results = {
        watts: 0,
        btus: 0,
      };
      this.resetFormKey++;
      this.scrollToCalculator();
    },
    async scrollToResults(): Promise<void> {
      await this.$nextTick();
      const results = (this as any).$refs.results.offsetTop;
      window.scrollTo({ top: results, behavior: "smooth" });
    },
    scrollToCalculator(): void {
      const calculator = (this as any).$refs.btuCalculator.offsetTop;
      window.scrollTo({ top: calculator, behavior: "smooth" });
    },
  },
});
