var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.taxonomy && _vm.taxonomy.length > 0)?_c('div',{staticClass:"hidden lg:grid grid-cols-2 gap-8"},_vm._l((_vm.sortAs(_vm.sortBy, _vm.taxonomy)),function(subDepartmentTaxonomy){return _c('div',{key:subDepartmentTaxonomy.taxonomy.id},[_c('div',{staticClass:"flex bg-white shadow"},[_c('div',{staticClass:"w-2/5 border-r-1 border-grey-2 p-4"},[_c('nuxt-link',{attrs:{"to":`/${_vm.rootSlug}/${subDepartmentTaxonomy.taxonomy.slug}/c${subDepartmentTaxonomy.taxonomy.id}`}},[_c('img',{attrs:{"src":subDepartmentTaxonomy.taxonomy.image,"height":"156","width":"156","loading":"lazy"}})])],1),_vm._v(" "),_c('div',{staticClass:"flex-1 p-4",class:{
          'flex items-center':
            !subDepartmentTaxonomy.children ||
            subDepartmentTaxonomy.children.length === 0,
        }},[_c('h4',{staticClass:"text-legacy-blue-default font-semibold mb-2"},[_c('nuxt-link',{attrs:{"to":`/${_vm.rootSlug}/${subDepartmentTaxonomy.taxonomy.slug}/c${subDepartmentTaxonomy.taxonomy.id}`}},[_vm._v(_vm._s(subDepartmentTaxonomy.taxonomy.name))])],1),_vm._v(" "),(
            subDepartmentTaxonomy.children &&
            subDepartmentTaxonomy.children.length > 0
          )?[_c('TopLevelDepartmentSubTaxonomyCardList',{key:subDepartmentTaxonomy.taxonomy.slug,attrs:{"root-slug":_vm.rootSlug,"sort-by":_vm.sortBy,"list":subDepartmentTaxonomy.children}})]:_vm._e()],2)])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }