<template>
  <DepartmentsNavigation
    :bloomreach-content="data"
    :is-open-by-default="isOpenByDefault"
  />
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import DepartmentsNavigation from "~/components/departments-navigation/DepartmentsNavigation.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(() => {
  const links = getContainerItemContent(
    props.component,
    props.page
  )?.anchorLink;

  if (links) {
    return links
      .map(({ anchorLink, anchorLinkName }) => {
        return {
          taxonomy: {
            slug: anchorLink,
            name: anchorLinkName,
          },
        };
      })
      .filter(({ taxonomy }) => taxonomy.slug && taxonomy.name);
  }

  return null;
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

const isOpenByDefault = computed(() => properties.value.isOpenByDefault);
</script>
