
import Vue, { PropType } from "vue";
import { Triangle } from "~/components/UI/icons/index";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

export default Vue.extend({
  name: "PromoBlocks",
  components: {
    Triangle,
    ResponsiveImage,
  },
  props: {
    showArrows: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: Object,
      required: false,
      default: () => {
        return {
          desktopSrc: "",
          tabletSrc: "",
          mobileSrc: "",
        };
      },
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    bannerContentColour: {
      type: String,
      required: false,
      default: "#ffff",
    },
    bannerBackgroundColour: {
      type: String,
      required: false,
      default: "#00000",
    },
    altBackgroundColour: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: String as PropType<String>,
      required: true,
    },
  },
});
