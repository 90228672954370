<template>
  <div class="h-full flex gap-6 md:gap-12 items-center" :class="imageClasses">
    <div class="w-full md:w-2/4 flex flex-col gap-4">
      <component
        :is="headingType"
        v-if="title"
        :class="titleAlignment"
        :style="titleColour"
      >
        {{ title }}
      </component>
      <div
        class="flex flex-col gap-6"
        :class="
          imagePosition.position === 'bottom' ? imagePosition.classes : ''
        "
      >
        <ResponsiveImage
          v-if="
            images &&
            (imagePosition.position === 'top' ||
              imagePosition.position === 'bottom')
          "
          :images="images"
          :alt-tag="title"
          classes="max-h-[250px] sm_md:max-w-[500px] w-full"
        />

        <div>
          <div
            v-if="body"
            :class="lineClamp"
            :style="bodyColour"
            v-html="body"
          />
          <span
            v-if="showReadMore"
            class="underline text-blue inline-block mt-2 cursor-pointer"
            @click="toggleBodyText"
            >{{ toggleButtonText }}</span
          >
        </div>
      </div>
      <DefaultButton
        v-if="cta.linkText"
        appearance="pill"
        size="lg"
        variant="primary"
        :path="cta.externalLink"
        class="mt-6 max-w-[300px] sm_md:mx-auto !ml-0"
        :style="buttonColour"
      >
        {{ cta.linkText }}
      </DefaultButton>
    </div>

    <ResponsiveImage
      v-if="
        images &&
        (imagePosition.position === 'left' ||
          imagePosition.position === 'right')
      "
      :images="images"
      :alt-tag="title"
      classes="max-h-[250px]', 'sm_md:max-w-[500px] w-full md:w-2/4"
    />
  </div>
</template>

<script>
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import ResponsiveImage from "~/components/shared/responsive-image/ResponsiveImage.vue";

export default {
  components: { DefaultButton, ResponsiveImage },
  props: {
    images: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: false,
      default: null,
    },
    cta: {
      type: Object,
      required: true,
    },
    headingType: {
      type: String,
      required: true,
    },
    titleColour: {
      type: String,
      required: true,
    },
    bodyColour: {
      type: String,
      required: true,
    },
    buttonColour: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: Object,
      required: true,
    },
    titleAlignment: {
      type: String,
      required: true,
    },
    bodyAlignment: {
      type: String,
      required: true,
    },
    showReadMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expandedBody: false,
    };
  },
  computed: {
    lineClamp() {
      return this.expandedBody || !this.showReadMore
        ? "lineClamp"
        : "lineClamp lineClamp--active";
    },
    toggleButtonText() {
      return !this.expandedBody ? "Read more" : "Read less";
    },
    imageClasses() {
      return this.imagePosition.position === "left" ||
        this.imagePosition.position === "right"
        ? this.imagePosition.classes
        : "";
    },
  },
  methods: {
    toggleBodyText() {
      this.expandedBody = !this.expandedBody;
    },
  },
};
</script>
