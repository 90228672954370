
import Vue from "vue";
import FormSelectOption from "./FormSelectOption.vue";

export default Vue.extend({
  name: "FormSelectOptionGroup",
  components: { FormSelectOption },
  props: {
    label: { type: String, required: true },
    options: { type: Array, default: () => [] },
  },
});
