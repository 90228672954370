
import Vue from "vue";
import BaseAccordion from "~/components/UI/accordion/BaseAccordion.vue";
import AccordionItem from "~/components/UI/accordion/AccordionItem.vue";
import LozengeLinks from "~/components/UI/links/LozengeLinks.vue";
import { fetchBrands } from "~/services/brands.service";
import { BrandGroup } from "~/services/brands.d";

export default Vue.extend({
  name: "BrandsListing",
  components: { BaseAccordion, AccordionItem, LozengeLinks },
  data(): { brands: BrandGroup[] } {
    return {
      brands: [],
    };
  },
  async fetch(): Promise<void> {
    this.brands = (await fetchBrands(this.$axios)) || [];
  },
  computed: {
    LozengeLinks(): { name: string; href: string }[] {
      if (!this.brands.length) return [];
      return this.brands.map((brand) => ({
        name: brand.title,
        href: `${brand.title.toLowerCase()}`,
      }));
    },
  },
});
