import { always, cond, equals, T } from "ramda";
import { ComponentParameters } from "~/composables/globalComponentProperties.d";

const objectFitCss = cond([
  [equals("none"), always("object-none")],
  [equals("cover"), always("object-cover")],
  [equals("contain"), always("object-contain")],
  [equals("fill"), always("object-fill")],
  [T, always("object-contain")],
]);

// Transforms component properties to their retrospective tailwind classes
// If we move from tailwind, we can perform the transforms here
// This just allows us to case match a human readable term in the CMS to a true "class" string here
export const transform = ({ objectFit }: ComponentParameters) => ({
  ...(objectFit ? { objectFit: objectFitCss(objectFit) } : null),
});
