<template>
  <div
    v-if="showBanner"
    class="fixed bottom-0 left-0 w-full p-3 bg-blue text-white z-[99] shadow-sm"
  >
    <div class="content-container flex items-center gap-2">
      <PreviewIcon />
      <p class="text-size-4 md:text-size-6">
        {{ $t("bloomreach.previewExperienceBanner") }} -
        <span class="font-semibold">{{ pageTitle }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import PreviewIcon from "~/components/UI/icons/PreviewIcon.vue";

export default {
  name: "BloomreachPagePreviewBanner",
  components: {
    PreviewIcon,
  },
  props: {
    showBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageTitle: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
