
import Vue, { PropType } from "vue";
import { sortAs } from "~/lib/taxonomies";
import { TaxonomyData } from "~/services/taxonomies.d";
export default Vue.extend({
  props: {
    list: Array as PropType<TaxonomyData[]>,
    sortBy: String as PropType<string>,
    rootSlug: String as PropType<string>,
  },
  data() {
    return { showMore: false, localList: this.$props.list };
  },
  methods: {
    sortAs,
    more() {
      this.showMore = !this.showMore;
    },
  },
});
