<template>
  <div
    v-if="data[0].imageUrl"
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div
      class="w-full h-full flex flex-col justify-center text-left content-container font-gilroy"
      :class="innerWrapperClasses.classes"
      :style="innerWrapperClasses.styles"
    >
      <BaseCarousel :settings="carouselSetting">
        <template #slides>
          <div
            v-for="(item, index) in data"
            :key="`${item.imageUrl + item.productTitle + index}`"
            class="flex flex-col items-center"
          >
            <img
              :src="item.imageUrl"
              :alt="item.productTitle"
              class="w-full object-contain p-4 max-w-[350px]"
              loading="lazy"
            />
            <p class="font-bold text-center mb-4" :style="titleTextColour">
              {{ item.productTitle }}
            </p>
            <div
              v-for="(subItem, subIndex) in item.iconAndTitle"
              :key="`${subItem.iconUrl + subItem.title + subIndex}`"
              class="flex flex-col gap-3 border-t-1 border-grey-3 p-6 w-full items-center"
            >
              <img
                :src="subItem.iconUrl"
                :alt="subItem.title"
                class="w-[80px]"
                loading="lazy"
              />
              <p class="text-center" :style="bodyTextColour">
                {{ subItem.title }}
              </p>
            </div>
            <DefaultButton
              v-if="item.cta.linkText"
              appearance="pill"
              size="lg"
              variant="primary"
              :path="item.cta.externalLink"
              class="w-full mt-4 sm:!max-w-[300px]"
              :style="ctaBtnColour"
            >
              {{ item.cta.linkText }}
            </DefaultButton>
          </div>
        </template>
      </BaseCarousel>
    </div>
  </div>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)?.productComparison ??
    null
);

// Deconstruct computed style properties from composable
const {
  wrapperClasses,
  innerWrapperClasses,
  ctaBtnColour,
  titleTextColour,
  bodyTextColour,
} = globalComponentProperties(properties, props.page);

const itemCount = computed(() => {
  return data.value.length;
});

const carouselSetting = {
  showDots: true,
  autoplayDelay: 6,
  slidesPerPage: itemCount.value >= 4 ? 4 : itemCount.value,
  paginateBySlide: true,
  showArrows: true,
  gutter: "4rem",
  responsive: [
    {
      maxWidth: 1024,
      slidesPerPage: itemCount.value >= 4 ? 4 : itemCount.value,
    },
    {
      maxWidth: 640,
      slidesPerPage: itemCount.value >= 2 ? 2 : itemCount.value,
      gutter: "2rem",
    },
  ],
};
</script>
