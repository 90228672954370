<template>
  <BrComponent component="main/container" />
</template>

<script>
import { BrComponent } from "@bloomreach/vue-sdk";

export default {
  name: "OneColumn",
  components: { BrComponent },
};
</script>

<style lang="postcss" scoped></style>
