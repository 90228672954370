<template>
  <div
    v-if="data"
    class="w-full relative py-14 sm:py-23 px-0"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <PromoBlocks
      :data="slides()"
      aspect-ratio-classes="aspect-[1.52] sm:aspect-[3.44]"
      :reduced-width="properties.reducedWidth"
      :slide-indicator-colour="slideIndicatorColour"
      :class="{ 'content-container': properties.reducedWidth }"
    />
  </div>
</template>

<script setup>
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import PromoBlocks from "~/components/UI/banners/PromoBlocks.vue";
import { promoSlideGroupToHref } from "~/lib";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component data fields from bloomreach SDK
const data = computed(() =>
  getContainerItemContent(props.component, props.page)
);

const slides = () => {
  return promoSlideGroupToHref(
    data?.value?.desktopSlides,
    data?.value?.mobileSlides
  );
};

const properties = computed(() => props.component.getParameters());

const { wrapperClasses, slideIndicatorColour } = globalComponentProperties(
  properties,
  props.page
);
</script>

<style lang="postcss" scoped></style>
