var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-auto flex flex-col gap-6 items-center"},[(_vm.images)?_c('ResponsiveImage',{attrs:{"images":_vm.images,"alt-tag":_vm.title,"classes":`h-full ${
      _vm.imageFit === 'cover'
        ? 'object-cover w-full'
        : 'object-contain w-1/2 lg:w-full'
    }`}}):_vm._e(),_vm._v(" "),(_vm.title || _vm.body)?_c('div',{staticClass:"flex flex-col gap-6 mt-2 h-full",class:[
      _vm.imagePosition.classes,
      { 'items-center md:items-start': _vm.imageFit === 'contain' },
    ]},[(_vm.title)?_c(_vm.headingType,{tag:"component",class:_vm.titleAlignment,style:(_vm.titleColour)},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',[(_vm.body)?_c('div',{class:[_vm.bodyAlignment, _vm.lineClamp],style:(_vm.bodyColour),domProps:{"innerHTML":_vm._s(_vm.body)}}):_vm._e(),_vm._v(" "),(_vm.showReadMore)?_c('span',{staticClass:"underline text-blue inline-block mt-2 cursor-pointer",on:{"click":_vm.toggleBodyText}},[_vm._v(_vm._s(_vm.toggleButtonText))]):_vm._e()]),_vm._v(" "),(_vm.cta?.linkText)?_c('DefaultButton',{staticClass:"w-full mt-auto max-w-[300px]",style:(_vm.buttonColour),attrs:{"appearance":"pill","size":"lg","variant":"primary","path":_vm.cta.externalLink}},[_vm._v("\n      "+_vm._s(_vm.cta.linkText)+"\n    ")]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }