var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{ref:"form",class:[
    'form-element',
    _vm.classes,
    { dirty: _vm.dirty },
    'sm:min-w-[345px]',
    'sm:max-w-[345px]',
  ],attrs:{"id":_vm.id,"method":_vm.method,"action":_vm.action,"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.bubble.apply(null, arguments)},"input":_vm.captureBubble,"click":_vm.captureBubble}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }