<template>
  <div v-if="items.length">
    <AccordionItem
      v-for="(item, index) in items"
      :key="index"
      icon-colour="background-color: #065CAB"
    >
      <template #title>
        <span :style="titleTextColour">{{ item.title }}</span>
      </template>
      <template #content>
        <div :style="bodyTextColour" v-html="item.text" />
      </template>
    </AccordionItem>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";

export default {
  name: "BaseAccordion",
  components: { AccordionItem },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    styleProperties: {
      type: Object,
      required: false,
      default: null,
    },
    titleTextColour: {
      type: String,
      required: false,
      default: "color: #065CAB",
    },
    bodyTextColour: {
      type: String,
      required: false,
      default: "color: #065CAB",
    },
  },
};
</script>
