
import { propEq } from "ramda";
import Vue, { PropType } from "vue";
import { TrackingEvents } from "~/store/tracking";
import { generateUrl } from "~/lib/shared";
import BaseCarousel from "~/components/UI/carousel/BaseCarousel.vue";
export interface CarouselItem {
  "mb-img": string;
  "mb-img-field": string;
  "mb-img-alt": string;
  "mb-img-link": string;
  "dt-img": string;
  "dt-img-100": string;
  "dt-img-alt-100": string;
  "dt-img-link-100": string;
  "dt-img-50-left": string;
  "dt-img-alt-50-left": string;
  "dt-img-link-50-left": string;
  "dt-img-50-right": string;
  "dt-img-alt-50-right": string;
  "dt-img-link-50-right": string;
  "dt-img-33-left": string;
  "dt-img-alt-33-left": string;
  "dt-img-link-33-left": string;
  "dt-img-33-center": string;
  "dt-img-alt-33-center": string;
  "dt-img-link-33-center": string;
  "dt-img-33-right": string;
  "dt-img-alt-33-right": string;
  "dt-img-link-33-right": string;
  "dt-img-50-left-3-split": string;
  "dt-img-alt-50-left-3-split": string;
  "dt-img-link-50-left-3-split": string;
  "dt-img-25-center-3-split": string;
  "dt-img-alt-25-center-3-split": string;
  "dt-img-link-25-center-3-split": string;
  "dt-img-25-right-3-split": string;
  "dt-img-alt-25-right-3-split": string;
  "dt-img-link-25-right-3-split": string;
}
export enum CarouselImageType {
  SingleImage = "1",
  DoubleImage = "2",
  TripleImage = "3",
  SplitImage = "4",
}
export default Vue.extend({
  components: { BaseCarousel },
  props: {
    carouselItems: {
      type: Array as PropType<CarouselItem[]>,
      default: () => [],
    },
  },
  data() {
    return {
      carouselSetting: {
        showDots: false,
        autoplayDelay: 10,
        slidesPerPage: 1,
        paginateBySlide: true,
        showArrows: false,
        loop: true,
      },
    };
  },
  computed: {
    filteredCarouselItems() {
      return this.carouselItems.filter((item) =>
        Object.values(CarouselImageType as Object).includes(item["dt-img"])
      );
    },
  },
  methods: {
    generateUrl,
    hasSingleImage: propEq("dt-img", CarouselImageType.SingleImage),
    hasDoubleImage: propEq("dt-img", CarouselImageType.DoubleImage),
    hasTripleImage: propEq("dt-img", CarouselImageType.TripleImage),
    hasSplitImages: propEq("dt-img", CarouselImageType.SplitImage),
    trackClick(image: string) {
      this.$store.commit("tracking/addTrackingEvent", {
        type: TrackingEvents.PromoClick,
        data: image,
      });
    },
  },
});
