var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"accordionItem",staticClass:"accordion-item",class:{
    'accordion-item--no-top-bottom-border': _vm.noBorderTopBottom,
    'accordion-item--no-padding': _vm.noPadding,
    'accordion-item--no-x-padding': _vm.noXPadding,
    'accordion-item--no-t-padding': _vm.noTPadding,
    'accordion-item--less-padding': _vm.lessPadding,
  }},[_c('h3',{staticClass:"accordion-item__title",on:{"click":function($event){return _setup.toggleItem()}}},[_vm._t("title"),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center p-1 rounded-full",class:_vm.altIcon || _vm.altIconR ? 'text-blue' : 'text-white',style:(_setup.props.iconColour)},[(!_vm.altIcon && !_vm.altIconR)?_c(_setup.PlusIcon,{attrs:{"size":"xxs","classes":`shrink-0 ease-in-out w-3 h-3 md:w-5 md:h-5 duration-300 ${
          _setup.expanded ? 'rotate-45' : ''
        }`}}):(_vm.altIcon)?_c(_setup.ChevronIcon,{attrs:{"classes":`shrink-0 ease-in-out w-5 h-5 md:w-7 md:h-7 duration-300 ${
          _setup.expanded ? 'rotate-180' : ''
        }`,"direction":"down"}}):_c(_setup.ChevronIcon,{attrs:{"classes":`shrink-0 ease-in-out w-5 h-5 md:w-7 md:h-7 duration-300 ${
          _setup.expanded ? 'rotate-90' : ''
        }`,"direction":"right"}})],1)],2),_vm._v(" "),(_vm.$slots['title-supporting-text'])?_c('h4',{staticClass:"accordion-item__supporting-text",on:{"click":function($event){return _setup.toggleItem()}}},[_vm._t("title-supporting-text")],2):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"accordion"},on:{"enter":_setup.start,"after-enter":_setup.end,"before-leave":_setup.start,"after-leave":_setup.end}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.expanded),expression:"expanded"}],staticClass:"accordion-item__body"},[_c('div',{staticClass:"pt-4"},[_vm._t("content")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }