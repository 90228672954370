<template>
  <div class="px-4 md:px-0">
    <BrComponent component="top-row/container" />
    <div class="content-container bg-white py-8 mb-8">
      <BrComponent component="bottom-row/container" />
    </div>
  </div>
</template>

<script>
import { BrComponent } from "@bloomreach/vue-sdk";

export default {
  name: "TwoRow",
  components: { BrComponent },
};
</script>

<style lang="postcss" scoped></style>
