<template>
  <button
    class="rounded-full h-[35px] cursor-pointer my-auto border-1 px-3 py-2 mx-1 flex items-center justify-center ease-in-out duration-200 text-size-4 whitespace-nowrap outline-none"
    :class="active ? 'bg-blue text-white' : 'bg-white text-blue'"
    :style="styles"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseLozenge",
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    styles: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style lang="postcss" scoped>
button {
  color: var(--color, #065cab);
  background-color: var(--background-color, #fff);
  border-color: var(--background-color--hover, #065cab);
}
button:hover {
  color: var(--color-hover, #fff);
  background-color: var(--background-color--hover, #065cab);
}
</style>
