
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import MenuItem from "~/components/account/MenuItem.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { MinusIcon, PlusIcon } from "~/components/UI/icons";

interface DepartmentsNavigation {
  taxonomy: {
    slug: string;
    name: string;
  };
  children: DepartmentsNavigation[];
}

export default Vue.extend({
  name: "DepartmentsNavigation",
  components: {
    MinusIcon,
    PlusIcon,
    MenuItem,
    DefaultButton,
  },
  props: {
    bloomreachContent: {
      type: Array as PropType<DepartmentsNavigation[]>,
      default: () => null,
    },
    isOpenByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    isOpen: boolean;
    activeChildItem: string;
  } {
    return {
      isOpen: this.isOpenByDefault,
      activeChildItem: "",
    };
  },
  computed: {
    ...mapGetters("locale", ["getDepartmentsHierarchy"]),
    ...mapGetters("viewport", ["isMobile"]),
    departments() {
      const currentPath = this.$route.path.replace("/categories/", "/");
      return (
        this.getDepartmentsHierarchy.find(
          (category: DepartmentsNavigation) =>
            category.taxonomy.slug === currentPath
        )?.children ||
        this.getDepartmentsHierarchy.map((category: DepartmentsNavigation) => ({
          taxonomy: category.taxonomy,
          children: [],
        }))
      );
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    toggleActiveChild(name: string) {
      this.activeChildItem = this.activeChildItem === name ? "" : name;
    },
  },
});
