<template>
  <div
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses?.classes"
    :style="wrapperClasses?.styles"
  >
    <div
      class="content-container"
      :class="[innerWrapperClasses?.classes, { 'md:!px-0': removeXPadding }]"
      :style="innerWrapperClasses?.styles"
    >
      <MultiTextAndImage
        :data="titleAndImageItemData"
        :main-title="mainTitle"
        :main-button="mainButton"
        :style-type="style"
        :height="height"
        :items-per-row-desktop="itemsPerRowDesktop"
        :items-per-row-tablet="itemsPerRowTablet"
        :items-per-row-mobile="itemsPerRowMobile"
        :is-carousel="isCarousel"
        :cta-btn-colour="ctaBtnColour"
        :title-text-colour="titleTextColour"
        :no-image-padding="noImagePadding"
        v-bind="customProps"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import MultiTextAndImage from "~/components/multi-text-and-image/MultiTextAndImage.vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import { languageString } from "~/composables/utils";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

const removeXPadding = ref(props.page.root.model.name === "top-level-category");

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () => getContainerItemContent(props.component, props.page) ?? null
);

const titleAndImageItemData = computed(
  () => data.value?.titleAndImageItem ?? null
);

// Deconstruct computed style properties from composable
const { wrapperClasses, innerWrapperClasses, ctaBtnColour, titleTextColour } =
  globalComponentProperties(properties, props.page);

const style = computed(() => {
  return properties.value.style;
});

const height = computed(() => {
  return properties.value.height;
});

const isCarousel = computed(() => {
  return properties.value.isCarousel;
});

const noImagePadding = computed(() => {
  return properties.value.noImagePadding;
});

const itemsPerRowDesktop = computed(() => {
  return Number(properties.value.itemsPerRowDesktop);
});
const itemsPerRowTablet = computed(() => {
  return Number(properties.value.itemsPerRowTablet);
});
const itemsPerRowMobile = computed(() => {
  return Number(properties.value.itemsPerRowMobile);
});

const mainTitle = computed(() => data.value?.title ?? "");
const mainButton = computed(() =>
  data.value?.button?.href ? data.value?.button : {}
);

const customProps = computed(() => {
  if (style.value !== "version 9") return {};
  return {
    "show-view-more-button": true,
    "collapsed-item-count": { desktop: 4, tablet: 4, mobile: 4 },
    "button-text": {
      collapsed: languageString("viewAll"),
      expanded: languageString("viewLess"),
    },
  };
});
</script>
