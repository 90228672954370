import { render, staticRenderFns } from "./BaseLozenge.vue?vue&type=template&id=159650f4&scoped=true"
import script from "./BaseLozenge.vue?vue&type=script&lang=js"
export * from "./BaseLozenge.vue?vue&type=script&lang=js"
import style0 from "./BaseLozenge.vue?vue&type=style&index=0&id=159650f4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159650f4",
  null
  
)

export default component.exports