<template>
  <div
    v-if="data"
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container flex flex-wrap justify-center md:!px-0">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="w-full md:w-1/2 lg:w-1/3 py-4 md:py-2 lg:py-4 px-2 lg:px-4 h-auto"
      >
        <div class="bg-white shadow-md h-full flex flex-col">
          <img
            class="w-full h-[250px] object-cover"
            :src="item.imageUrl"
            :alt="item.title"
            loading="lazy"
          />
          <div class="flex-1 flex flex-col">
            <div>
              <h2 class="p-6 pb-0 font-bold text-blue text-size-7">
                {{ item.title }}
              </h2>
              <ul class="px-6 py-4 font-semibold text-blue text-size-6">
                <li
                  v-for="(linkItem, linkItemIndex) in item.linkItem"
                  :key="linkItemIndex"
                  class="py-3 border-b-1 border-grey-3 last:border-none"
                >
                  <a class="list-link" :href="linkItem.externalLink">
                    <span>{{ linkItem.linkText }}</span>
                    <ChevronIcon direction="right" classes="list-link__icon" />
                  </a>
                </li>
              </ul>
            </div>
            <a
              v-if="item.bottomCta.externalLink"
              class="pb-5 text-center text-blue underline flex justify-center items-end flex-1"
              :href="item.bottomCta.externalLink"
              >{{ item.bottomCta.linkText }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import { ChevronIcon } from "~/components/UI/icons";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Return component data fields from bloomreach SDK
const data = computed(
  () =>
    getContainerItemContent(props.component, props.page)
      ?.listLinkAndImageItem ?? null
);

// Deconstruct computed style properties from composable
const { wrapperClasses } = globalComponentProperties(properties, props.page);
</script>

<style lang="postcss" scoped>
.list-link {
  @apply flex justify-between;

  &:hover {
    .list-link__icon {
      @apply bg-blue-800;
    }
  }

  &__icon {
    @apply bg-blue rounded-full w-[3rem] h-[3rem] text-white p-1 ease-in-out duration-300;
  }
}
</style>
