
import Vue from "vue";
import FormSelectOption from "./FormSelectOption.vue";
import FormSelectOptionGroup from "./FormSelectOptionGroup.vue";
import { WithRefs } from "~/utils/vue-typed-refs";

type Refs = {
  input: HTMLInputElement;
};

export default (Vue as WithRefs<Refs>).extend({
  name: "FormSelect",
  components: { FormSelectOption, FormSelectOptionGroup },
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    id: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
    form: { type: String, required: false, default: undefined },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    modelValue: { type: [String, Array, Object, Number], default: "" },
    isStateValid: {
      type: Boolean,
      default: null,
    },
    legacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dirty: false,
    };
  },
  computed: {
    classes() {
      return [
        {
          dirty: this.dirty,
          "form-select legacy": this.legacy,
          "is-valid": this.isStateValid === true,
          "is-invalid": this.isStateValid === false,
        },
        "md:min-w-[227px]",
        "md:max-w-[227px]",
      ];
    },
  },
  watch: {
    modelValue(newValue): void {
      this.$emit("input", newValue);
    },
  },
  methods: {
    onChange(event: Event) {
      this.dirty = true;
      const target = event.target as HTMLSelectElement;
      const selectedVal = Array.from(target?.options)
        .filter((option) => option.selected)

        .map((option) =>
          "_value" in option ? (option as any)._value : option.value
        );

      this.$nextTick(() => {
        this.$emit("change", target.multiple ? selectedVal : selectedVal[0]);
        this.$emit(
          "update:modelValue",
          target.multiple ? selectedVal : selectedVal[0]
        );
      });
    },
  },
});
