<template>
  <div
    class="w-full py-16 sm:py-23"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div class="content-container bg-white shadow-sm p-4">
      <ul class="flex flex-col md:flex-row flex-wrap">
        <li
          v-for="(link, index) in links"
          :key="index"
          class="text-blue hover:text-blue-hover cursor-pointer hover:bg-grey-1 ease-in-out duration-200 py-4"
        >
          <nuxt-link :to="link.path" class="p-4">
            {{ link.name }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import "@bloomreach/spa-sdk";
import { computed } from "vue";
import { globalComponentProperties } from "~/composables/globalComponentProperties";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  page: {
    type: Object,
    required: true,
  },
});

// Return component properties from bloomreach SDK
const properties = computed(() => props.component.getParameters());

// Deconstruct computed style properties from composable
const { wrapperClasses } = globalComponentProperties(properties, props.page);

// Get links document
const links = computed(() => {
  const { document } = props.component.getModels();
  return (
    document &&
    props.page.getContent(document).model.data.link.map((link) => {
      return { path: link.externalLink, name: link.linkText };
    })
  );
});
</script>

<style lang="postcss" scoped>
.nuxt-link-active {
  @apply bg-grey-1;
}
</style>
