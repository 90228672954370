<template>
  <div
    class="w-full relative py-16 sm:py-23 font-gilroy"
    :class="wrapperClasses.classes"
    :style="wrapperClasses.styles"
  >
    <div
      class="content-container"
      :class="{ 'px-4 md:px-[12rem] lg:px-[16rem]': properties?.reducedWidth }"
    >
      <div v-if="data" :style="innerBgColour">
        <AccordionItem
          v-for="(item, index) in data"
          :key="index"
          :icon-colour="iconColour"
        >
          <template #title>
            <span :style="titleTextColour">{{ item.title }}</span>
          </template>
          <template #content>
            <div :style="bodyTextColour" v-html="item.text.value" />
          </template>
        </AccordionItem>
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import { strippedContent } from "~/services/helpers";
import AccordionItem from "~/components/UI/accordion/AccordionItem.vue";

export default {
  components: { AccordionItem },
  props: {
    component: {
      type: Object,
      required: false,
      default: null,
    },
    page: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    // Return component properties from bloomreach SDK or styleProperties prop
    const properties = computed(() => props.component?.getParameters());

    const iconColour = computed(() => {
      switch (properties.value?.accordionIconColour) {
        case "Title Text Colour":
          return `background-color: ${getColour(titleTextColour.value)}`;
        case "Body Text Colour":
          return `background-color: ${getColour(bodyTextColour.value)}`;
        default:
          return `background-color: #065CAB`;
      }
    });

    // Deconstruct computed style properties from composable
    const { wrapperClasses, titleTextColour, bodyTextColour, innerBgColour } =
      globalComponentProperties(properties, props.page);

    // Return component data fields from bloomreach SDK
    const data = computed(() => {
      return !props.page
        ? null
        : getContainerItemContent(props.component, props.page)?.titleAndText ??
            null;
    });

    const items = computed(() => {
      if (!data.value) return [];
      return data.value.map((item) => {
        return { title: item.title, text: item.text.value };
      });
    });

    const useFaqSchema = computed(() => {
      return properties.value?.useFaqSchema;
    });

    const structuredFaqData = ref({
      "@context": "http://schema.org",
      "@type": "FAQPage",
      mainEntity: [],
    });

    if (useFaqSchema.value) {
      items.value.forEach((item) => {
        structuredFaqData.value.mainEntity.push({
          "@type": "Question",
          name: item.title,
          acceptedAnswer: {
            "@type": "Answer",
            text: strippedContent(item.text),
          },
        });
      });
    }

    function getColour(value) {
      return value.split(":")[1];
    }

    return {
      structuredFaqData,
      wrapperClasses,
      titleTextColour,
      bodyTextColour,
      data,
      items,
      properties,
      useFaqSchema,
      iconColour,
      innerBgColour,
    };
  },
  head() {
    if (this.useFaqSchema) {
      return {
        script: [
          {
            type: "application/ld+json",
            json: this.structuredFaqData,
          },
        ],
      };
    }
  },
};
</script>
